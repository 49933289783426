import React from 'react';
import {WithHelperDisplay} from './types';
import escapeSpecialRegexChars from './escapeSpecialRegexChars';

const withHelperMatchHighlighter = (searchValue: string) =>
  (
    (regExpMatcher: RegExp) =>
    ({label, helper, ...rest}: WithHelperDisplay) => ({
      label: (
        <React.Fragment>
          {label.split(regExpMatcher).map((fragment, index) =>
            index % 2 === 0 ? (
              fragment
            ) : (
              <span data-highlight key={index}>
                {fragment}
              </span>
            )
          )}
        </React.Fragment>
      ),
      ...(helper
        ? {
            helper: (
              <React.Fragment>
                {helper.split(regExpMatcher).map((fragment, index) =>
                  index % 2 === 0 ? (
                    fragment
                  ) : (
                    <span data-highlight key={index}>
                      {fragment}
                    </span>
                  )
                )}
              </React.Fragment>
            )
          }
        : {}),
      ...rest
    })
  )(new RegExp(`(${escapeSpecialRegexChars(searchValue)})`, 'ig'));

export default withHelperMatchHighlighter;
