import {UIEvent, useCallback, useLayoutEffect, useRef, useState} from 'react';
import debounce from 'lodash/debounce';

export enum ScrollDirection {
  UNINITIALISED = 'UNINITIALISED',
  DOWN = 'DOWN',
  UP = 'UP'
}

const isOverScroll = ({
  availableScrollAreaHeight,
  currentScrollPosition,
  visibleViewportHeight
}: {
  availableScrollAreaHeight: number;
  currentScrollPosition: number;
  visibleViewportHeight: number;
}): boolean => currentScrollPosition < 0 || currentScrollPosition > availableScrollAreaHeight - visibleViewportHeight;

type Params = {
  topOffset?: number;
};

export const useScrollDirection = ({topOffset = 0}: Params = {}): [
  scrollDirection: ScrollDirection,
  handleScroll: (event: UIEvent | Event) => void
] => {
  const [scrollDirection, setScrollDirection] = useState(ScrollDirection.UNINITIALISED);

  const previousScrollPosition = useRef(0);
  const previousAvailableScrollAreaHeight = useRef(0);

  const handleScroll = useCallback(
    debounce((event: UIEvent | Event) => {
      // In case the handleScroll callback is attached as an event listener to the window
      const documentEventTarget = event.target as Document;
      // In case the handleScroll callback is attached as an event listener to any other element
      const htmlElementTarget = event.target as HTMLElement;

      const visibleViewportHeight = htmlElementTarget.offsetHeight || documentEventTarget.body?.offsetHeight || 0;
      const availableScrollAreaHeight =
        htmlElementTarget.scrollHeight || documentEventTarget.scrollingElement?.scrollHeight || 0;
      const currentAndPreviousScrollAreaDiff =
        previousAvailableScrollAreaHeight.current > 0
          ? availableScrollAreaHeight - previousAvailableScrollAreaHeight.current
          : 0;
      const currentScrollPosition = documentEventTarget.scrollingElement?.scrollTop || htmlElementTarget.scrollTop || 0;

      // The scroll area might change dynamically due to an element getting expanded/collapsed or due
      // to a lot of elements being added in the page due to pagination (e.g. a show more button).
      const currentScrollPositionBasedOnScrollArea =
        // Covers the case of small scroll area adjustments due to e.g. an expand/collapse of an element
        currentScrollPosition - currentAndPreviousScrollAreaDiff < 0 ||
        // Covers the case of a lot of elements being added to the page (e.g. pagination)
        Math.abs(currentAndPreviousScrollAreaDiff) > visibleViewportHeight
          ? currentScrollPosition
          : currentScrollPosition - currentAndPreviousScrollAreaDiff;

      const isOnTop = currentScrollPosition === 0;
      const wasOnTop = previousScrollPosition.current === 0;

      if (
        !isOverScroll({
          availableScrollAreaHeight,
          currentScrollPosition,
          visibleViewportHeight
        })
      ) {
        const isScrollUp = isOnTop || previousScrollPosition.current > currentScrollPositionBasedOnScrollArea;
        const isScrollDown =
          (wasOnTop && currentScrollPosition > topOffset) ||
          (previousScrollPosition.current < currentScrollPositionBasedOnScrollArea &&
            (currentScrollPosition > topOffset || currentScrollPositionBasedOnScrollArea > topOffset));

        if (isScrollUp) {
          requestAnimationFrame(() => {
            setScrollDirection(ScrollDirection.UP);
          });
        } else if (isScrollDown) {
          requestAnimationFrame(() => {
            setScrollDirection(ScrollDirection.DOWN);
          });
        }
      }

      previousScrollPosition.current = currentScrollPosition;
      previousAvailableScrollAreaHeight.current = availableScrollAreaHeight;
    }, 120),
    [topOffset]
  );

  return [scrollDirection, handleScroll];
};

export const useWindowScrollDirection = (params: Params = {}): ScrollDirection => {
  const [scrollDirection, handleScroll] = useScrollDirection(params);

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return scrollDirection;
};
