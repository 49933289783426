export const routesPaths = Object.freeze({
  HOME: '/',
  SEARCH: {
    index: '/search',
    subPaths: [':location', ':location/:query']
  },
  JOB: '/view',
  COMPANY: '/company',
  CANDIDATE: '/candidate',
  PROFILE: {
    index: '/profile',
    subRoutes: {
      MY_PROFILE: 'my-profile',
      APPLIED_JOBS: 'applied-jobs',
      SETTINGS: 'settings'
    }
  },
  BACKEND_ACCOUNT_DELETE: '/profile/delete', // This is the path that the backend handles the account deletion
  LOGIN: '/login',
  LOGOUT: '/auth/logout',
  SUCCESSFUL_UNSUBSCRIBE: '/successful-unsubscribe'
});

export const SEARCH_DYNAMIC_LANDING_ROUTES = routesPaths.SEARCH.subPaths.map(subPath => ({
  path: `${routesPaths.SEARCH.index}/${subPath}`,
  caseSensitive: false,
  end: false
}));
