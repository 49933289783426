import React from 'react';
import clsx from "clsx";
import DefaultOption from 'shared/ui/organisms/listbox/listItem/simple/selectable';
import styles from './styles.scss';

const DropDownOption = ({as: Kind = DefaultOption, group, ...optProps}) => (
  <Kind
    {...optProps}
    className={clsx([{[styles.grouped]: group}], optProps.className)}
    group={group}
  />
);

DropDownOption.displayName = 'DropDown.Option';

export default DropDownOption;
