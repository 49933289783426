import getRandomString from 'shared/ui/helpers/getRandomString';
import colors from '../css/functions/color.scss';
import {isHexString} from 'shared/helpers/isHexString';

const isProduction = process && process.env && process.env.NODE_ENV === 'production';
const allColorNames = Object.keys(colors);

const warnForInvalidColor = allowed => {
  if (!isProduction) {
    // eslint-disable-next-line no-console
    console.error(
      [
        'The background prop passed is not within the allowed background',
        `colors. Please use on of the following: ${allowed.join(', ')}`
      ].join(' ')
    );
  }
};

const warnForNonExistingColor = color => {
  if (!isProduction) {
    // eslint-disable-next-line no-console
    console.error(`Can not colorpick for ${color}. This color does not exist in known colors`);
  }
};

export default function () {
  return {
    allowedColors: [],
    strictPalette(options) {
      if (!options || !(options instanceof Array) || !options.length) {
        return this;
      }
      this.allowedColors = options;
      return this;
    },

    pick(color) {
      let foundColor;

      if (!color) {
        return;
      }

      if (this.allowedColors && this.allowedColors.length) {
        foundColor =
          this.allowedColors.indexOf(color) > -1 ? color : this.allowedColors.find(name => colors[name] === color);

        if (!foundColor) {
          warnForInvalidColor(this.allowedColors);
          return;
        }
      } else {
        foundColor = colors.hasOwnProperty(color) ? color : Object.keys(colors).find(name => colors[name] === color);
      }

      if (foundColor) {
        return foundColor in colors ? colors[foundColor] : isHexString(foundColor) ? foundColor : undefined;
      }

      warnForNonExistingColor(color);
    },

    pickRandomColorName(seed = getRandomString()) {
      const checksum = seed
        .split('')
        .reduce((acc, seeding) => (acc + seeding.charCodeAt(0)) % (this.allowedColors.length + seed.length), 0);
      const colorName =
        this.allowedColors && this.allowedColors.length
          ? this.allowedColors[checksum % this.allowedColors.length]
          : allColorNames[checksum % allColorNames.length];

      return colorName;
    },

    pickRandom(seed = getRandomString()) {
      const colorName = this.pickRandomColorName(seed);

      return colorName in colors ? colors[colorName] : isHexString(colorName) ? colorName : undefined;
    }
  };
}
