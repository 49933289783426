import {useTranslation} from 'react-i18next';
import {makePageDataSelector} from 'job-board/shared/utils/pageDataSelector';

type BackendValueMethods = {
  pickBackendMeta: (key?: BackendMetaKey) => string | undefined;
  pickBackendUrl: (key?: BackendUrlKey) => string | undefined;
};

export const useBackendValue = (): BackendValueMethods => {
  const {i18n: _i18n} = useTranslation();

  return {
    pickBackendMeta: makePageDataSelector(window.jobBoard.meta, _i18n.language),
    pickBackendUrl: makePageDataSelector(window.jobBoard.urls, _i18n.language)
  };
};
