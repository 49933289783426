import {useCallback} from 'react';
import {shallowEqual, useDispatch} from 'react-redux';
import {GetUserDataResponse} from 'job-board/api/types/getUserData';
import {useSelector} from 'job-board/redux';
import {selectIsUserAuthenticated, selectUserData} from './selectors';
import {clearState, clearUserDataDueToError, setUserData, UserDataState} from './slice';

export const useUserDataActions = () => {
  const dispatch = useDispatch();

  return {
    clearState: useCallback(() => dispatch(clearState()), []),
    clearUserDataDueToError: useCallback(() => dispatch(clearUserDataDueToError()), []),
    setUserData: useCallback((payload: GetUserDataResponse) => {
      dispatch(setUserData(payload));
    }, [])
  };
};

export const useUserData = (): UserDataState => useSelector(selectUserData, shallowEqual);

export const useIsUserAuthenticated = (): boolean => useSelector(selectIsUserAuthenticated);
