import {useContext} from 'react';
import {type Basename, BasenameContext} from './provider';

export const useBasename = (): NonNullable<Basename> => {
  const context = useContext(BasenameContext);

  if (!context) {
    throw new Error('useBasename must be used within a BasenameProvider');
  }

  return context;
};
