import React, {memo, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import {routesPaths} from 'job-board/routes/constants';
import styles from './jobBoardLogo.scss';

export type JobBoardLogoProps = {
  className?: string;
  isLinkable: boolean;
  isMobile: boolean;
  logoAsset?: string;
};

const JobBoardLogo = ({className, isLinkable, isMobile, logoAsset}: JobBoardLogoProps): JSX.Element | null => {
  const {t} = useTranslation();
  const [hasError, setHasError] = useState(false);

  const handleError = useCallback(() => {
    setHasError(true);
  }, []);

  const ariaLabel = t('header.logo.title', {
    partnerName: t('page-data.partnerTradeName')
  });

  const {logo: defaultLogo} = window.jobBoard.assets;

  const logo = (
    <img
      alt={!isLinkable ? ariaLabel : ''}
      aria-hidden={isLinkable}
      className={clsx(styles.logo, {[styles.mobile]: isMobile}, className)}
      onError={handleError}
      src={logoAsset ?? defaultLogo}
    />
  );

  if (hasError) {
    return null;
  }

  return isLinkable ? (
    <Link aria-label={ariaLabel} data-ui="logo-button" to={routesPaths.HOME}>
      {logo}
    </Link>
  ) : (
    logo
  );
};

export default memo(JobBoardLogo);
