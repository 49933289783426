import React, {memo} from 'react';

export type SvgProps = React.SVGAttributes<SVGSVGElement> & {
  id: string;
  title?: string;
};

const Svg = ({id, title, ...rest}: SvgProps) => {
  return (
    <svg aria-hidden={!title} {...rest}>
      {!!title && <title>{title}</title>}
      <use href={`#${id}`} />
    </svg>
  );
};

export default memo(Svg);
