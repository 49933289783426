import {useCallback, useEffect} from 'react';
import {useAnalytics} from 'job-board/contexts/analytics/hooks';
import {HeapProperty} from 'job-board/shared/analytics/events/heap';
import {FEATURE_FLAGS} from 'job-board/shared/constants/featureFlags';
import {useUserDataActions} from 'job-board/shared/redux/userData/hooks';
import type {AuthMessage} from 'job-board/shared/services/auth';
import {AuthChannel} from 'job-board/shared/services/auth';

const useHandleBroadcastAuth = (): void => {
  const {clearUserDataDueToError, setUserData} = useUserDataActions();
  const {addHeapEventProperties, heapIdentify, removeHeapEventProperties, resetHeapIdentity} = useAnalytics();

  const handleMessage = useCallback((message: MessageEvent<AuthMessage>) => {
    if (message.origin === window.location.origin) {
      if ('error' in message.data) {
        clearUserDataDueToError();
        resetHeapIdentity();
        removeHeapEventProperties(HeapProperty.LOGGED_IN);
      } else if ('success' in message.data) {
        setUserData(message.data.success);
        heapIdentify(message.data.success.id);
        addHeapEventProperties({[HeapProperty.LOGGED_IN]: 'true'});
      }
    }
  }, []);

  useEffect(() => {
    if (FEATURE_FLAGS.IS_PROFILE_ENABLED) {
      const authChannel = AuthChannel.getInstance();
      authChannel.addEventListener('message', handleMessage);

      return () => {
        authChannel.removeEventListener('message', handleMessage);
      };
    }
  }, []);
};

export default useHandleBroadcastAuth;
