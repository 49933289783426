import React, {memo, useMemo} from 'react';
import Avatar from 'shared/ui/organisms/avatar';
import TextImage from 'shared/ui/atoms/image/text';
import getInitialLetters from 'shared/helpers/getNameInitials';

const ImageOrTextAvatar = memo(({email, src, name, size, hideInitials, children}) => {
  const textImage = useMemo(() => {
    if (hideInitials || ((!name || !name.trim()) && !email)) {
      return undefined;
    }

    return <TextImage text={getInitialLetters({name: name || email})} seed={`${email}`} />;
  }, [hideInitials, name, email]);

  return hideInitials || name || email ? (
    <Avatar size={size} title={name || email} url={src} fallback={textImage}>
      {!src ? textImage : null}
      {children}
    </Avatar>
  ) : null;
});

export default ImageOrTextAvatar;
