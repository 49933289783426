import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {GetUserDataResponse} from 'job-board/api/types/getUserData';

export type UserDataState = {
  data: GetUserDataResponse | null;
  isUninitialized?: boolean;
};

export const initialState: UserDataState = {
  data: null,
  isUninitialized: true
};

const userDataSlice = createSlice({
  initialState,
  name: 'userData',
  reducers: {
    clearState: () => initialState,
    clearUserDataDueToError: () => ({
      ...initialState,
      isUninitialized: false
    }),
    setUserData: (_, action: PayloadAction<GetUserDataResponse>) => ({data: action.payload, isUninitialized: false})
  }
});

export const {clearState, clearUserDataDueToError, setUserData} = userDataSlice.actions;

export default userDataSlice.reducer;
