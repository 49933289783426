import React, {createContext, ReactNode, useEffect, useMemo, useRef} from 'react';
import createAnalyticsClient, {AnalyticsClient} from 'job-board/shared/analytics/clients/gtm';
import createQueueClient, {AnalyticsQueueClient} from 'job-board/shared/analytics/queues/gtm';

export const AnalyticsContext = createContext<AnalyticsClient | AnalyticsQueueClient | undefined>(undefined);

export const AnalyticsProvider = ({children}: {children: ReactNode}) => {
  const clientRef = useRef<AnalyticsQueueClient | AnalyticsClient>(createQueueClient());

  useEffect(() => {
    void createAnalyticsClient().then((_client: AnalyticsClient | undefined) => {
      if (_client) {
        (clientRef.current as AnalyticsQueueClient).runQueue(_client);
        clientRef.current = _client;
      }
    });
  }, []);

  // The previous implementation had the client as a state (useState) which was updated inside the above useEffect
  // when the analytics client was ready. When the client was updated the references to all the analytics methods
  // (heapEvent, etc.) would change resulting to all useEffects having these methods as a dependency to unnecessarily
  // re-run.
  const client = useMemo<AnalyticsClient>(
    () => ({
      addHeapEventProperties: (...args) => {
        clientRef.current.addHeapEventProperties(...args);
      },
      addHeapEventProperty: (...args) => {
        clientRef.current.addHeapEventProperty(...args);
      },
      gaEvent: (...args) => {
        clientRef.current.gaEvent(...args);
      },
      heapEvent: (...args) => {
        clientRef.current.heapEvent(...args);
      },
      heapIdentify: (...args) => {
        clientRef.current.heapIdentify(...args);
      },
      pageview: (...args) => {
        clientRef.current.pageview(...args);
      },
      removeHeapEventProperties: (...args) => {
        clientRef.current.removeHeapEventProperties(...args);
      },
      removeHeapEventProperty: (...args) => {
        clientRef.current.removeHeapEventProperty(...args);
      },
      resetHeapIdentity: (...args) => {
        clientRef.current.resetHeapIdentity(...args);
      },
      variable: (...args) => {
        clientRef.current.variable(...args);
      }
    }),
    []
  );

  return <AnalyticsContext.Provider value={client}>{children}</AnalyticsContext.Provider>;
};
