import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import close16 from 'assets/svg/close.svg?sprite';
import drag16 from 'assets/svg/drag16.svg?sprite';
import globe16 from 'assets/svg/globe16.svg?sprite';
import ButtonPrimary from 'shared/ui/atoms/button/primary';
import ButtonSecondary from 'shared/ui/atoms/button/secondary';
import SVGLoader from 'shared/ui/atoms/icon/SVGLoaders/sprite';
import type {PseudoEvent} from 'shared/ui/helpers/createPseudoEvent';
import IconButton from 'shared/ui/molecules/button/icon';
import Select, {type ControlSelectProps} from 'shared/ui/molecules/control/select';
import Modal, {Content} from 'shared/ui/organisms/dialog/modal';
import {routesPaths} from 'job-board/routes/constants';
import Svg from 'job-board/shared/components/svg';
import {FEATURE_FLAGS} from 'job-board/shared/constants/featureFlags';
import {useBackendValue} from 'job-board/shared/hooks/useBackendValue';
import {useLanguage} from 'job-board/shared/hooks/useLanguage';
import {getAvailableLanguages, hasLanguageOptions} from 'job-board/shared/utils/locale';
import styles from './burgerMenu.scss';

const LanguageDisplayContainer: ControlSelectProps['displayContainer'] = () => {
  const {selectedLanguage} = useLanguage();

  return (
    <div className={styles['language-display-container']}>
      <SVGLoader fill size={16} svg={globe16} transparent />
      {selectedLanguage?.label}
    </div>
  );
};

type ToggleProps = {
  isOpen: boolean;
  onClick: () => void;
};

const Toggle = ({isOpen, onClick}: ToggleProps): JSX.Element => {
  const {t} = useTranslation();

  return (
    <IconButton
      aria-label={t('header.burger-menu.label')}
      className={styles['icon-button']}
      elevation="2"
      onClick={onClick}
      white
    >
      <Svg height={16} id={isOpen ? close16.id : drag16.id} width={16} />
    </IconButton>
  );
};

export const BurgerMenu = (): JSX.Element => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {t} = useTranslation();
  const {pickBackendUrl} = useBackendValue();
  const {selectedLanguage, updateLanguage} = useLanguage();

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const toggleMenu = useCallback(() => {
    window.scrollTo({behavior: 'smooth', top: 0});
    setIsMenuOpen(isOpen => !isOpen);
  }, []);

  const languageOptions = useMemo<ControlSelectProps['options']>(
    () =>
      getAvailableLanguages().map(({code, label}) => ({
        display: label,
        value: code
      })),
    []
  );

  const handleLanguageChange = useCallback<(e: PseudoEvent<string>) => void>(
    async e => {
      await updateLanguage(e.target.value);
      closeMenu();
    },
    [closeMenu, updateLanguage]
  );

  const employersPage = pickBackendUrl('employersPage');

  const IS_VISIBLE = {
    EMPLOYERS_LINK: !!employersPage,
    LANGUAGE_PICKER: hasLanguageOptions(),
    LOGIN_BUTTON: FEATURE_FLAGS.IS_PROFILE_ENABLED
  };

  return (
    <div className={styles.container}>
      <Toggle isOpen={isMenuOpen} onClick={toggleMenu} />
      <Modal
        className={styles.modal}
        DismissIcon={<Toggle isOpen={isMenuOpen} onClick={toggleMenu} />}
        mobile={false}
        onClose={closeMenu}
        open={isMenuOpen}
        wrapperClassName={styles['modal-wrapper']}
      >
        <Content className={styles.menu} ugly>
          {IS_VISIBLE.LOGIN_BUTTON && (
            <ButtonPrimary as={Link} className={styles.item} onClick={closeMenu} to={routesPaths.LOGIN}>
              {t('header.login-button')}
            </ButtonPrimary>
          )}
          {IS_VISIBLE.EMPLOYERS_LINK && (
            <ButtonSecondary
              className={styles.item}
              data-ui="employers-link"
              href={employersPage}
              rel="noreferrer"
              target="_self"
            >
              {t('header.employers')}
            </ButtonSecondary>
          )}
          {IS_VISIBLE.LANGUAGE_PICKER && (
            <Select
              className={styles['language-picker']}
              data-ui="language-dropdown-trigger"
              displayContainer={LanguageDisplayContainer}
              onChange={handleLanguageChange}
              options={languageOptions}
              value={selectedLanguage?.code}
            />
          )}
        </Content>
      </Modal>
    </div>
  );
};
