import noop from 'lodash/noop';
import type {GetUserDataResponse} from 'job-board/api/types/getUserData';

interface AuthBroadcastChannel extends BroadcastChannel {
  postMessage: (message: AuthMessage) => void;
}

export type AuthChannelType = AuthBroadcastChannel | undefined;

export enum ErrorCode {
  RESPONSE_ERROR = 'RESPONSE_ERROR',
  UNKNOWN = 'UNKNOWN',
  EXCEPTION = 'EXCEPTION',
  EMAIL_CONFLICT = 'LOGIN_EMAIL_CONFLICTS_WITH_APPLICATION_EMAIL'
}

type EmailConflictError = {
  code: ErrorCode.EMAIL_CONFLICT;
  data?: {
    applicationEmail?: string;
    authenticationEmail?: string;
  };
};

type NonEmailConflictError = {
  code: Exclude<ErrorCode, ErrorCode.EMAIL_CONFLICT>;
  message?: string;
  status?: number;
  statusText?: string;
  type?: string;
};

export type AuthSuccessResponse = {
  success: GetUserDataResponse;
};

export type AuthErrorResponse = {
  error: EmailConflictError | NonEmailConflictError;
};

export type AuthMessage = AuthSuccessResponse | AuthErrorResponse;

export const CHANNEL_NAME = 'WJB_AUTH';
export const AuthChannel = (() => {
  let authChannel: AuthChannelType;
  return {
    __testReset() {
      if (__ENV__ === 'test') {
        authChannel = undefined;
      }
    },
    getInstance(setup?: (authChannel: AuthChannelType) => void) {
      if (!authChannel) {
        if (global.BroadcastChannel) {
          authChannel = new BroadcastChannel(CHANNEL_NAME);

          if (typeof setup === 'function') {
            setup(authChannel);
          }
        } else {
          authChannel = {
            addEventListener: (...params: Parameters<typeof window.addEventListener>) => {
              window.addEventListener(...params);
            },
            close: noop,
            dispatchEvent: noop,
            postMessage: (message: MessageEvent<AuthMessage>) => {
              if (window.opener) {
                (window.opener as Window).postMessage(message, window.location.origin);
              }
            },
            removeEventListener: (...params: Parameters<typeof window.removeEventListener>) => {
              window.removeEventListener(...params);
            }
          } as unknown as BroadcastChannel;
        }
      }

      return authChannel;
    }
  };
})();
