import {Option, WithHelperDisplay} from './types';

const withHelperFiltering = (option: Option<WithHelperDisplay>, searchValue: string): boolean => {
  const includesSearchValue = (searchableString: string) =>
    searchableString.toLowerCase().includes(searchValue.toLowerCase());

  if (!option.display || !('label' in option.display) || typeof option.display.label !== 'string') {
    return false;
  }

  const {label, helper} = option.display;

  return includesSearchValue(label) || (!!helper && includesSearchValue(helper));
};

export default withHelperFiltering;
