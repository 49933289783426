const SUCCESS_STATUS = /^2\d{2}$/;
const SERVER_ERROR_STATUS = /^5\d{2}$/;

export const isSuccessStatus = (status: number | string): boolean => {
  if (!status) {
    return false;
  }
  return SUCCESS_STATUS.test(status.toString());
};

export const isServerErrorStatus = (status: number | string): boolean => {
  if (!status) {
    return false;
  }
  return SERVER_ERROR_STATUS.test(status.toString());
};

export const isUnauthorisedStatus = (status: number | string): boolean => status === 401 || status === '401';
