export const defaultAvatar = `data:image/svg+xml,${encodeURIComponent(
  `<svg width="120" height="120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M48 51c0 6.6 5.4 12 12 12s12-5.4 12-12v-3c0-6.6-5.4-12-12-12s-12 5.4-12 12v3Zm6 18h12c8.4001 0 15 6.6 15 15H39c0-8.4 6.6-15 15-15Z" fill="#6a7381"/>
    <path d="M120 60c0 33.1371-26.8629 60-60 60-33.1371 0-60-26.8629-60-60C0 26.8629 26.8629 0 60 0c33.1371 0 60 26.8629 60 60Z" fill="#f3f5f8"/>
    <mask id="a" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="120" height="120">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M60 120c33.1371 0 60-26.8629 60-60 0-33.1371-26.8629-60-60-60C26.8629 0 0 26.8629 0 60c0 33.1371 26.8629 60 60 60Z" fill="#919bab"/>
    </mask>
    <g mask="url(#a)" fill="#919bab">
      <path opacity=".6" d="M15 120.587c0-24.5988 19.9452-44.4997 44.5-44.4997 24.5991 0 44.5 19.9452 44.5 44.4997"/>
      <path opacity=".4" fill-rule="evenodd" clip-rule="evenodd" d="M15 120.587h37.7334c0-15.023-7.4491-28.3199-18.8657-36.38C22.4581 92.2595 15 105.545 15 120.587Z"/>
      <path d="M59.5003 84.1725c12.9493 0 23.4467-10.4975 23.4467-23.4467 0-12.9493-10.4974-23.4467-23.4467-23.4467S36.0536 47.7765 36.0536 60.7258c0 12.9492 10.4974 23.4467 23.4467 23.4467Z"/>
    </g>
  </svg>`
)}`;
