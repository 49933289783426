import React, {memo, useCallback, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router';
import check16 from 'assets/svg/check16.svg?sprite';
import globe16 from 'assets/svg/globe16.svg?sprite';
import globe24 from 'assets/svg/globe24.svg?sprite';
import clsx from 'clsx';
import ButtonPrimary, {type ButtonPrimaryProps} from 'shared/ui/atoms/button/primary';
import ButtonTertiary, {type ButtonTertiaryProps} from 'shared/ui/atoms/button/tertiary';
import SVGLoader from 'shared/ui/atoms/icon/SVGLoaders/sprite';
import DropdownButton from 'shared/ui/molecules/button/dropdown';
import {useBasename} from 'job-board/contexts/basename/hooks';
import {useLanguage, USER_LANGUAGE_KEY} from 'job-board/shared/hooks/useLanguage';
import {getAvailableLanguages, getLocaleFromPathname, getUrlWithoutLocale} from 'job-board/shared/utils/locale';
import styles from './languagePicker.scss';

const Trigger = ({isMobile, ...props}: ButtonTertiaryProps & {isMobile: boolean}): JSX.Element => {
  const {selectedLanguage} = useLanguage();

  return (
    <ButtonTertiary
      {...props}
      className={clsx(styles['language-dropdown-trigger'], props.className)}
      data-ui="language-dropdown-trigger"
    >
      {isMobile ? (
        <SVGLoader fill size={24} svg={globe24} transparent />
      ) : (
        <>
          <SVGLoader fill size={16} svg={globe16} transparent />
          {selectedLanguage?.label}
        </>
      )}
    </ButtonTertiary>
  );
};

type LanguagePickerProps = {
  isMobile: boolean;
};

export const LanguagePicker = memo(({isMobile}: LanguagePickerProps) => {
  const {i18n} = useTranslation();
  const availableLanguages = getAvailableLanguages();
  const navigate = useNavigate();
  const {setBasename} = useBasename();
  const {updateLanguage} = useLanguage();

  const {hash, pathname, search} = location;

  const navigateToCleanUrl = useCallback(() => {
    navigate(getUrlWithoutLocale({hash, pathname, search}));
    setBasename(undefined);
  }, [navigate, setBasename, pathname, search, hash]);

  useEffect(() => {
    const hasLanguagePreference = localStorage.getItem(USER_LANGUAGE_KEY);
    const hasLocaleInUrl = getLocaleFromPathname(pathname);

    if (hasLanguagePreference && hasLocaleInUrl) {
      navigateToCleanUrl();
    }
  }, [navigateToCleanUrl, pathname]);

  const handleLanguageChange = useCallback<NonNullable<ButtonPrimaryProps['onClick']>>(
    async event => {
      const language = event.currentTarget.getAttribute('data-language');

      if (language) {
        await updateLanguage(language);
        navigateToCleanUrl();
      }
    },
    [updateLanguage, navigateToCleanUrl]
  );

  return (
    <DropdownButton as={Trigger} isMobile={isMobile} right>
      {availableLanguages.map(({code, label}) => (
        <ButtonPrimary data-language={code} key={code} onClick={handleLanguageChange}>
          <span className={styles['language-option']}>
            {i18n.language === code ? <SVGLoader fill svg={check16} transparent /> : <div />}
            {label}
          </span>
        </ButtonPrimary>
      ))}
    </DropdownButton>
  );
});

LanguagePicker.displayName = 'LanguagePicker';
