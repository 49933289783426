import React from 'react';
import clsx from "clsx";
import PropTypes from 'prop-types';
import symbols from 'shared/ui/symbols';
import Check from 'shared/ui/atoms/icon/check';
import BaseSimpleItem from '../base';
import Display from '../display';
import styles from './styles.scss';

const Option = React.memo(({display, selected, group, children, virtualized, ...rest}) => {
  return (
    (<BaseSimpleItem virtualized={virtualized} {...rest} className={clsx({
      [styles["no-padding-left"]]: group || selected,
      [styles.selectable]: true
    }, rest.className)}>
      {selected && <Check emphasis size="16" />}
      <Display group={group} selected={selected} display={display} truncated={virtualized}>
        {children}
      </Display>
    </BaseSimpleItem>)
  );
});

Option.propTypes = {
  /** The description of the option that will be displayed at the user. */
  display: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** Controls whether this option is disabled or not. If disabled, user is unable to select it. */
  disabled: PropTypes.bool,
  /** Controls whether this option is selected or not. */
  selected: PropTypes.bool,
  /** Controls whether this option is centered or not. */
  centered: PropTypes.bool,
  /** Controls whether this option is active or not. */
  active: PropTypes.bool,
  /** If set to true, denotes the specific option has a nested group, therefor is a group header */
  group: PropTypes.bool,
  /** Controls whether text will be truncated  */
  truncated: PropTypes.bool
};

Option.displayName = 'Listbox.ListItem.SimpleSelectable';

Option[symbols.ListItem.SimpleSelectable] = true;

export default Option;
