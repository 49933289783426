/**
 * Helper function to find the closest ancestor element by ID.
 * @param {HTMLElement} element - The starting element to search from.
 * @param {string} id - The ID of the ancestor element to find.
 * @returns {HTMLElement|null} - The closest ancestor element with the specified ID, or null if not found.
 */
export default function getClosestById(element: Element | null, id: string): Element | null {
  // Check if the input ID is valid
  if (!id || typeof id !== 'string') {
    // eslint-disable-next-line no-console
    console.warn('A valid ID string must be provided.');
    return null;
  }

  // Traverse up the DOM tree
  while (element) {
    // If the current element's ID matches, return it
    if (element.id === id) {
      return element;
    }
    // Move to the parent element
    element = element.parentElement;
  }

  // Return null if no matching element is found
  return null;
}
