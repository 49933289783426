import {useContext} from 'react';
import type Rollbar from 'rollbar';
import {RollbarContext} from './provider';

export const useRollbar = (): Rollbar | null | never => {
  const rollbar = useContext(RollbarContext);
  if (typeof rollbar === 'undefined') {
    throw new Error('useRollbar must be used within a RollbarContext');
  }
  return rollbar;
};
