import React from 'react';
import clsx from "clsx";
import propsFilter from 'shared/ui/helpers/propsFilter';
import InputSearch from 'shared/ui/atoms/input/search';
import MagnifyingGlassIcon from 'shared/ui/atoms/icon/magnifyingGlass';
import styles from './styles.scss';

const Search = props => {
  const composePropsFilter = propsFilter(props).styles().dataAttributes();
  const allowedProps = composePropsFilter.getFiltered();
  const restProps = composePropsFilter.excludeFiltered();

  return (
    (<div {...allowedProps} className={clsx(styles["search-input-wrapper"], allowedProps.className)} data-role="search">
      <InputSearch {...restProps}>
        <MagnifyingGlassIcon muted left size="16" />
      </InputSearch>
    </div>)
  );
};

Search.displayName = 'ListBox.Search';

export default Search;
