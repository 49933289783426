import {useEffect} from 'react';
import {register, unregister} from 'job-board/shared/utils/swRegistration';

export default (options?: RegistrationOptions): void => {
  const backedConfig: PreBackedConfig = window.jobBoard.config;
  const swDisabled = backedConfig?.swDisabled;

  useEffect(() => {
    if (swDisabled) {
      unregister()
        .then(() => {
          window.location.reload();
        })
        .catch(e => {
          throw e;
        });
    } else {
      register({
        ...options,
        onUpdate: registration => {
          void registration?.waiting?.postMessage({type: 'SKIP_WAITING'});
        }
      });
    }
  }, [options, swDisabled]);
};
