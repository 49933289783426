import React, {useMemo, useState, useCallback} from 'react';
import clsx from "clsx";
import PropTypes from 'prop-types';
import symbols from 'shared/ui/symbols';

import BaseListBox from '../base';

import styles from './styles.scss';

export const GROUP_HEADER_ATTRIBUTE = 'data-group-header';

const NOOP = () => {};

const findExpandableGroup = (items, value) => {
  return items.find(item => item.value === value && Array.isArray(item.options) && !item.presentational);
};

const getDefaultExpandedGroups = (items, defaultExpandedGroups, toggleExpandedGroup) => {
  if (defaultExpandedGroups.length === 0 && toggleExpandedGroup) {
    return [];
  }

  const groups = defaultExpandedGroups.reduce((_groups, defaultGroup) => {
    const group = findExpandableGroup(items, defaultGroup);
    return group ? [..._groups, group] : _groups;
  }, []);

  if (toggleExpandedGroup && groups.length > 1) {
    return [groups[0]];
  }

  if (groups.length > 0) {
    return groups;
  }

  return [];
};

const CollapsibleListBox = ({
  items,
  as: Listbox = BaseListBox,
  defaultExpandedGroups: _defaultExpandedGroups = [],
  toggleExpandedGroup,
  onGroupExpand = NOOP,
  onSelect = NOOP,
  ...props
}) => {
  const defaultExpandedGroups = getDefaultExpandedGroups(items, _defaultExpandedGroups, toggleExpandedGroup);
  const [expandedGroups, setExpandedGroups] = useState(defaultExpandedGroups.map(group => group.value));

  const listItems = useMemo(() => {
    return items.map(item => {
      const isGroup = Array.isArray(item.options) && !item.presentational;

      if (!isGroup) {
        return item;
      }

      const expanded = expandedGroups.includes(item.value);

      return {
        ...item,
        collapsible: true,
        expanded,
        [GROUP_HEADER_ATTRIBUTE]: true,
        'aria-expanded': expanded,
        options: item.options.map(opt => {
          opt.visible = expanded;
          return opt;
        })
      };
    });
  }, [items, expandedGroups]);

  const updateSingleExpandedGroup = useCallback(
    value => {
      setExpandedGroups([value]);
      onGroupExpand({value, expanded: true});
    },
    [expandedGroups, onGroupExpand]
  );

  const addExpandedGroup = useCallback(
    value => {
      setExpandedGroups([...expandedGroups, value]);
      onGroupExpand({value, expanded: true});
    },
    [expandedGroups, onGroupExpand]
  );

  const removeExpandedGroup = useCallback(
    value => {
      setExpandedGroups(expandedGroups.filter(expandedGroup => expandedGroup !== value));
      onGroupExpand({value, expanded: false});
    },
    [expandedGroups, onGroupExpand]
  );

  const handleSelection = useCallback(
    (item, event) => {
      const selectedValue = item.getAttribute('value');
      const isGroup = item.getAttribute(GROUP_HEADER_ATTRIBUTE) === 'true';

      if (!isGroup) {
        onSelect(item, event);
        return;
      }

      const isAlreadyExpanded = expandedGroups.includes(selectedValue);

      if (isAlreadyExpanded) {
        removeExpandedGroup(selectedValue);
        return;
      }

      if (toggleExpandedGroup) {
        updateSingleExpandedGroup(selectedValue);
        return;
      }

      addExpandedGroup(selectedValue);
    },
    [expandedGroups, toggleExpandedGroup, addExpandedGroup, removeExpandedGroup, onSelect]
  );

  return <Listbox {...props} className={clsx(styles["collapsible-listbox"], props.className)} items={listItems} onSelect={handleSelection} />;
};

CollapsibleListBox[symbols.ListBox] = true;

CollapsibleListBox.propTypes = {
  ...BaseListBox.propTypes,
  /** The listbox component to be used */
  as: PropTypes.elementType,
  /** The default expanded group */
  defaultExpandedGroups: PropTypes.arrayOf(PropTypes.string),
  /** Allows only one open group. Opening a new one will close the other. */
  toggleExpandedGroup: PropTypes.bool,
  /** Called when group is expanded */
  onGroupExpand: PropTypes.func
};

export default CollapsibleListBox;
