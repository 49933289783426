import {useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {createCookieString} from 'shared/helpers/cookie';
import {useGetUserProfilePreferencesQuery} from 'job-board/api/jobBoardApi';
import {useUserData} from 'job-board/shared/redux/userData/hooks';
import {getAvailableLanguages} from '../utils/locale';

type UpdateLanguage = (language: string) => Promise<void>;

export const USER_LANGUAGE_KEY = 'user_language';

export const useLanguage = (): {
  selectedLanguage: LanguageConfig | undefined;
  updateLanguage: UpdateLanguage;
} => {
  const {i18n} = useTranslation();
  const {data: userData} = useUserData();
  const {data: userPreferences, refetch} = useGetUserProfilePreferencesQuery(undefined, {skip: !userData});

  const currentLanguage = i18n.language;
  const userLanguagePreference = userPreferences?.language;

  const selectedLanguage = useMemo<LanguageConfig | undefined>(() => {
    const language = userLanguagePreference || currentLanguage;

    return getAvailableLanguages().find(({code}) => code === language);
  }, [currentLanguage, userLanguagePreference]);

  const updateLanguage = useCallback<UpdateLanguage>(
    async (language: string) => {
      await i18n.changeLanguage(language);

      localStorage.setItem(USER_LANGUAGE_KEY, language);

      document.cookie = createCookieString(USER_LANGUAGE_KEY, language);

      document.documentElement.lang = language;

      // Refetch only if we already have a user profile
      if (userPreferences) {
        await refetch();
      }
    },
    [i18n, userPreferences, refetch]
  );

  return {
    selectedLanguage,
    updateLanguage
  };
};
