/**
 * This helper method calculates the position of each satellite on a circle with a given `radius`
 * over a specific arch on the circle defined by `degreesStart` and `degreesStop`.
 *
 * Each satellite's position is related on its `index` among all the satellites placed in this
 * arch along with the number of `totalSatellites` that are going to be placed and its `size`
 * that will occupy as an entity on the arch.
 * If there is antiquate space for all satellites on the arc then they will be justified in the
 * arc leaving some space around the first and last element.
 * A good parallelism would be flex property `justify-content: space-around` [|-A--B--C-|]
 *
 * As an example:
 * If there are about to be placed total of 1 satellites in an arch between 0 & 90
 * degrees then its angle will be at 45 degrees from the starting point (0 degrees).
 * If there are about to be placed total of 2 satellites in the same arch the angle of 1st would be
 * 30 degrees and the 2nd would be 60 degrees from the starting point.
 *
 * Yet, if there is not enough space in the arc and the satellites start colliding with each
 * other then the placement of the elements starts from the starting point spreading them equally
 * up to the end of the arc. Again, a good parallelism for that would be the flex property
 * `justify-content: space-between` [|A---B---C|]
 *
 * **Detecting when satellites start colliding**
 * Having in mind that satellites are in themselves circles sitting on top of a bigger circle
 * (the avatar) all of these circles have the same diameter which is equal to their `size` property
 * hence it's radius is `size/2`.
 *
 * By calculating the range of the arch in radians and dividing by
 * the total number of satellites it becomes known the distance in radians between two centers of
 * subsequent satellites. Using this distance to calculate the smaller arch of their in-between
 * -centers which now enables the calculation of their `chord`, meaning the straight distance
 * between the two satellite centers sitting on top of the avatar circle.
 *
 * Collision means the two circles touch each other when the total distance between their centers
 * is lower or equal with the sum of their radiuses. Since both satellites have equal radiuses which
 * is equal to one's diameter. Diameter in this case is the same as the `size` given.
 *
 * So to figure if colliding or not and how many more hypothetical satellites fit between two
 * subsequent satellites is given by the `offset` which is simply the division between the chord
 * of two centers with the sum of their two radiuses (again, same as width). If chord is smaller
 * than the size returns a zero(0) with decimals like 0.5. We get the integer value of that.
 * If is 0 means the two satellites collided while any other X value means there could be X more
 * satellites between them before a collision occurs.
 *
 * The calculation of the `angle` of each satellite makes use of this offset value to push a
 * satellite further from its current position on the arch or leave it at its initial placement.
 *
 * This will not ensure two satellites will never collide, if simply there are that many satellites
 * on the arch which they occupy all of its extend, collision is inevitable.
 */
const calculateSatellitePosition = ({size, radius, index, totalSatellites, degreesStart = 0, degreesStop = 90}) => {
  const position = {
    left: 0,
    top: 0
  };
  const radiansStart = (degreesStart * Math.PI) / 180;
  const radiansStop = (degreesStop * Math.PI) / 180;

  const radiansRange = radiansStop - radiansStart;
  const chord = 2 * radius * Math.sin(((radiansRange / totalSatellites) * radius) / (2 * radius));
  const offset = window.parseInt(chord / size);
  const radianStep = radiansRange / (totalSatellites - 1 + offset);
  const angle = -(radiansStart + radianStep * index + (radianStep * offset) / (totalSatellites + offset));

  position.top = (radius + size / 4) * Math.sin(angle) - size / 2;
  position.left = (radius + size / 4) * Math.cos(angle) - size / 2;

  return position;
};

export default calculateSatellitePosition;
