import React, {createContext, ReactNode, useState} from 'react';

export const ServerErrorContext = createContext<boolean | undefined>(undefined);
export const ServerErrorUpdaterContext = createContext<((hasServerError: boolean) => void) | undefined>(undefined);

export const ServerErrorProvider = ({children}: {children: ReactNode}) => {
  const [hasServerError, setHasServerError] = useState(false);

  return (
    <ServerErrorContext.Provider value={hasServerError}>
      <ServerErrorUpdaterContext.Provider value={setHasServerError}>{children}</ServerErrorUpdaterContext.Provider>
    </ServerErrorContext.Provider>
  );
};
