import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type AlreadyAppliedJobIdsState = string[];

export const initialState: AlreadyAppliedJobIdsState = [];

const alreadyAppliedJobIds = createSlice({
  initialState,
  name: 'alreadyAppliedJobIds',
  reducers: {
    clearState: () => initialState,
    setAppliedJobId: (state, action: PayloadAction<string>) => [...state, action.payload]
  }
});

export const {clearState, setAppliedJobId} = alreadyAppliedJobIds.actions;

export default alreadyAppliedJobIds.reducer;
