import React, {memo} from 'react';
import clsx from 'clsx';
import {Device, useDevice} from 'shared/contexts/device';
import ResponsiveProvider from 'shared/contexts/device/responsive';
import TertiaryButton from 'shared/ui/atoms/button/tertiary';
import facebookIcon from 'job-board/assets/svg/facebook.svg?sprite';
import instagramIcon from 'job-board/assets/svg/instagram.svg?sprite';
import linkedinIcon from 'job-board/assets/svg/linkedin.svg?sprite';
import tikTokIcon from 'job-board/assets/svg/tiktok.svg?sprite';
import xIcon from 'job-board/assets/svg/x.svg?sprite';
import youtubeIcon from 'job-board/assets/svg/youtube.svg?sprite';
import JobBoardLogo from 'job-board/shared/components/jobBoardLogo';
import Svg from 'job-board/shared/components/svg';
import {MOBILE_FIRST_MEDIA_QUERIES} from 'job-board/shared/mediaQueries';
import styles from './topRow.scss';

type SocialMediaLinkProps = {
  ariaLabel: string;
  className?: string;
  iconId: string;
  url: string;
};

const SocialMediaLink = ({ariaLabel, className, iconId, url}: SocialMediaLinkProps): JSX.Element => {
  return (
    <li className={clsx(styles['icon-list-item'], className)}>
      <TertiaryButton aria-label={ariaLabel} href={url} rel="noreferrer" target="_blank">
        <Svg className={styles.icon} id={iconId} />
      </TertiaryButton>
    </li>
  );
};

const TopRow = memo(() => {
  const [device] = useDevice();
  const {facebook, instagram, linkedin, tiktok, twitter, youtube} = window.jobBoard.urls;

  if (device === Device.Mobile && !facebook && !linkedin && !twitter && !instagram && !youtube && !tiktok) {
    return null;
  }

  return (
    <div className={styles.container}>
      {device === Device.Desktop && (
        <JobBoardLogo
          className={styles.logo}
          isLinkable={false}
          isMobile={false}
          logoAsset={window.jobBoard.assets.footerLogo}
        />
      )}
      <ul className={styles['icons-container']}>
        {!!tiktok && <SocialMediaLink ariaLabel="tiktok" iconId={tikTokIcon.id} url={tiktok} />}
        {!!facebook && <SocialMediaLink ariaLabel="facebook" iconId={facebookIcon.id} url={facebook} />}
        {!!linkedin && <SocialMediaLink ariaLabel="linkedIn" iconId={linkedinIcon.id} url={linkedin} />}
        {!!twitter && (
          <SocialMediaLink ariaLabel="x (twitter)" className={styles['x-twitter']} iconId={xIcon.id} url={twitter} />
        )}
        {!!instagram && (
          <SocialMediaLink
            ariaLabel="instagram"
            className={styles.instagram}
            iconId={instagramIcon.id}
            url={instagram}
          />
        )}
        {!!youtube && (
          <SocialMediaLink ariaLabel="youtube" className={styles.youtube} iconId={youtubeIcon.id} url={youtube} />
        )}
      </ul>
    </div>
  );
});

TopRow.displayName = 'TopRow';

const ResponsiveTopRow = (): JSX.Element => (
  <ResponsiveProvider mobileMediaQueries={MOBILE_FIRST_MEDIA_QUERIES}>
    <TopRow />
  </ResponsiveProvider>
);

export default ResponsiveTopRow;
