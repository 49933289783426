import React from 'react';
import Text from 'shared/ui/atoms/text';
import EllipsisWithTooltip from 'shared/ui/molecules/ellipsisWithTooltip';

const Body = React.memo(({group, selected, display, children, ...props}) => (
  <Text.Body {...(group ? {emphasis: true} : {neutral: !selected})} strong={group} {...props}>
    {display || children}
  </Text.Body>
));

const Display = ({group, selected, display, truncated, children}) => {
  return (
    <span>
      {truncated ? (
        <EllipsisWithTooltip tooltipProps={{title: display}}>
          <Body group={group} selected={selected} display={display} children={children} />
        </EllipsisWithTooltip>
      ) : (
        <Body group={group} selected={selected} display={display} children={children} />
      )}
    </span>
  );
};

Display.displayName = 'Listbox.ListItem.SimpleSelectableDisplay';

export default React.memo(Display);
