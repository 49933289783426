import React from 'react';

import {SimpleDisplay as Display} from './types';
import escapeSpecialRegexChars from './escapeSpecialRegexChars';

const matchHighlighter = (searchValue: string) =>
  ((regExpMatcher: RegExp) => (subject: Display) => {
    const hasLabel = typeof subject !== 'string' && 'label' in subject;
    const display = typeof subject === 'string' ? subject : subject.label;

    const displayFragment = (
      <React.Fragment>
        {display
          ? display.split(regExpMatcher).map((fragment, index) =>
              index % 2 === 0 ? (
                fragment
              ) : (
                <span data-highlight key={index}>
                  {fragment}
                </span>
              )
            )
          : ''}
      </React.Fragment>
    );

    if (hasLabel) {
      return {
        ...subject,
        label: displayFragment
      };
    }
    return displayFragment;
  })(new RegExp(`(${escapeSpecialRegexChars(searchValue)})`, 'ig'));

export default matchHighlighter;
