import {buildUrlSearchParams, QueryParams} from './urlSearchParams';

const buildUrl = (baseUrl: string, queryParams?: QueryParams): string => {
  if (!queryParams) {
    return baseUrl;
  }

  const searchParams = buildUrlSearchParams(queryParams);

  return searchParams ? `${baseUrl}?${searchParams}` : baseUrl;
};

export default buildUrl;
