import React, {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useMemo,
  useState
} from 'react';
import {getLocaleFromPathname, isLanguageAvailable} from 'job-board/shared/utils/locale';

export type Basename =
  | {
      basename: string | undefined;
      setBasename: Dispatch<SetStateAction<string | undefined>>;
    }
  | undefined;

export const BasenameContext = createContext<Basename>(undefined);

export const BasenameProvider = ({children}: PropsWithChildren<Record<never, never>>): JSX.Element => {
  const [basename, setBasename] = useState(() => {
    const urlLocale = getLocaleFromPathname(location.pathname)?.replace(/\//g, '');

    return isLanguageAvailable(urlLocale) ? `/${urlLocale}` : undefined;
  });

  const value = useMemo(() => ({basename, setBasename}), [basename]);

  return <BasenameContext.Provider value={value}>{children}</BasenameContext.Provider>;
};
