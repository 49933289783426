import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import search16 from 'assets/svg/search16.svg?sprite';
import TextBody from 'shared/ui/atoms/text/body';
import Svg from 'job-board/shared/components/svg';
import {FEATURE_FLAGS} from 'job-board/shared/constants/featureFlags';
import styles from './searchJobsButton.scss';

export type SearchJobsButtonProps = {
  isMobile: boolean;
  isSearchButtonExpanded?: boolean;
  onSearchButtonClick?: () => void;
};

const SearchJobsButton = ({isMobile, isSearchButtonExpanded, onSearchButtonClick}: SearchJobsButtonProps) => {
  const {t} = useTranslation();

  const shouldShowText = !FEATURE_FLAGS.IS_PROFILE_ENABLED || !isMobile;

  return (
    <button
      aria-expanded={!isMobile ? isSearchButtonExpanded : undefined}
      aria-label={!shouldShowText ? t('header.search.button') : undefined}
      className={styles.button}
      data-ui="search-for-jobs-button"
      onClick={onSearchButtonClick}
    >
      <div className={styles.content}>
        {shouldShowText && (
          <TextBody className={styles.text} strong>
            {t('header.search.button')}
          </TextBody>
        )}
        <Svg className={styles.icon} id={search16.id} />
      </div>
    </button>
  );
};

export default memo(SearchJobsButton);
