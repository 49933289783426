export enum JobState {
  ARCHIVED = 'archived',
  CLOSED = 'closed',
  DRAFT = 'draft',
  PUBLISHED = 'published'
}

export enum Workplace {
  ON_SITE = 'on_site',
  HYBRID = 'hybrid',
  REMOTE = 'remote'
}

type Location = {
  city?: string;
  countryName?: string;
  subregion?: string;
};

export type Company = {
  description: string;
  id: string;
  image?: string;
  title: string;
  url: string;
  website: string;
};

export type GetJobResponse = {
  applicationDate?: string;
  applyUrl: string;
  benefitsSection: string;
  company: Company;
  created: string;
  department?: string;
  description: string;
  employmentType?: string;
  externalDomain?: string;
  id: string;
  isFeatured?: boolean;
  language?: string;
  location: Location;
  locations: string[];
  requirementsSection: string;
  state: JobState;
  title: string;
  trackingPixelUrl: string;
  updated: string;
  url: string;
  workplace: Workplace;
};

export type TransformedJob = {
  applicationDate: string;
  applyUrl: string;
  benefitsSection: string;
  company: Company & {
    relativeUrl: string;
  };
  dateCreated: string;
  dateUpdated: string;
  department: string;
  description: string;
  employmentType: string;
  externalDomain?: string;
  id: string;
  isFeatured: boolean;
  language?: string;
  location: string;
  relativeUrl: string;
  requirementsSection: string;
  state: JobState;
  title: string;
  trackingPixelUrl: string;
  url: string;
  workplace: Workplace;
};
