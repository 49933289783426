import {combineReducers} from '@reduxjs/toolkit';
import {jobBoardApi} from 'job-board/api/jobBoardApi';
import companyData from 'job-board/routes/company/redux/slice';
import alreadyAppliedJobIds from 'job-board/shared/redux/alreadyAppliedJobIds/slice';
import userData from 'job-board/shared/redux/userData/slice';

const rootReducer = combineReducers({
  [jobBoardApi.reducerPath]: jobBoardApi.reducer,
  alreadyAppliedJobIds,
  companyData,
  userData
});

export default rootReducer;
