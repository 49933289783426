import React from 'react';
import clsx from "clsx";
import PropTypes from 'prop-types';
import symbols, {hasSymbol} from 'shared/ui/symbols';
import BaseListBox from '../base';
import {
  matchHighlighter,
  withHelperMatchHighlighter,
  filtering as defaultFiltering,
  withHelperFiltering
} from 'shared/ui/helpers/listbox';
import styles from './styles.scss';

export const doDeepFilter = (options, searchValue, filter, highlightingFn) => {
  const filtered = [];

  if (!searchValue) {
    return options;
  }

  const highlight = highlightingFn(searchValue);

  options.forEach(option => {
    const matched = filter(option, searchValue);
    const isGroup = option.options instanceof Array;
    const isPresentational = option.presentational;
    const clonedOption = {...option};

    if (isGroup) {
      const nestedFiltered = doDeepFilter(option.options, searchValue, filter, highlightingFn);
      if ((matched && !isPresentational) || nestedFiltered.length) {
        filtered.push(clonedOption);
      }
      clonedOption.options = nestedFiltered.length ? nestedFiltered : undefined;
      clonedOption.disabled = !matched || isPresentational;
      return;
    }

    if (matched) {
      if (!isPresentational) {
        clonedOption.display = highlight(clonedOption.display || clonedOption.value);
        clonedOption.search = searchValue;
        filtered.push(clonedOption);
      } else {
        filtered.push(clonedOption);
      }
    }
  });

  return filtered;
};

const getFilteringAndHighlightingFn = (Item, {highlighting, filtering}) => {
  const getHighlightingFn = fallbackFn => (typeof highlighting === 'function' ? highlighting : fallbackFn);
  const getFilteringFn = fallbackFn => (typeof filtering === 'function' ? filtering : fallbackFn);

  if (
    hasSymbol(symbols.ListItem.SimpleSelectableWithHelper, Item.type) ||
    hasSymbol(symbols.ListItem.WithAvatar, Item.type)
  ) {
    return {
      highlightingFn: getHighlightingFn(withHelperMatchHighlighter),
      filteringFn: getFilteringFn(withHelperFiltering)
    };
  }

  return {
    highlightingFn: getHighlightingFn(matchHighlighter),
    filteringFn: getFilteringFn(defaultFiltering)
  };
};

const FilteredListBox = ({searchValue, items, filtering, highlighting, Item, ...props}) => {
  const {highlightingFn, filteringFn} = getFilteringAndHighlightingFn(Item, {filtering, highlighting});

  const filteredItems = doDeepFilter(items, searchValue, filteringFn, highlightingFn);

  return (
    <BaseListBox {...props} className={clsx({
      [styles.filtered]: true,
      [styles.virtualized]: props.virtualized
    }, props.className)} items={filteredItems} Item={Item} />
  );
};

FilteredListBox[symbols.ListBox] = true;

FilteredListBox.propTypes = {
  ...BaseListBox.propTypes,
  /**
   * A filter function which will be executed for each option against the search query
   * Default: 'shared/ui/helpers/listbox/filtering'
   * @param {object} option
   * @param {string} searchValue
   * @returns {boolean}
   * */
  filtering: PropTypes.func,
  /**
   * The value to search for among the items
   */
  searchValue: PropTypes.string,
  /**
   * A highlighter function which will be executed for each option against the search query.
   * Default: 'shared/ui/helpers/listbox/matchHighlighter'
   */
  highlighting: PropTypes.func
};

export default FilteredListBox;
