import React, {Suspense} from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import ButtonTertiary from 'shared/ui/atoms/button/tertiary';
import JobBoardLogo from 'job-board/shared/components/jobBoardLogo';
import {FEATURE_FLAGS} from 'job-board/shared/constants/featureFlags';
import {useBackendValue} from 'job-board/shared/hooks/useBackendValue';
import {ScrollDirection, useWindowScrollDirection} from 'job-board/shared/hooks/useScrollDirection';
import {useUserData} from 'job-board/shared/redux/userData/hooks';
import lazyLoadWithRetry from 'job-board/shared/utils/lazyImport';
import {hasLanguageOptions} from 'job-board/shared/utils/locale';
import BackButton from './components/backButton';
import {BurgerMenu} from './components/burgerMenu';
import {LanguagePicker} from './components/languagePicker';
import LoginButton from './components/loginButton';
import SearchJobsButton from './components/searchJobsButton';
import styles from './header.scss';

const ProfileAvatar = lazyLoadWithRetry(
  () => import(/* webpackChunkName: 'component.profileAvatar' */ './components/profileAvatar'),
  'component.profileAvatar'
);

export type HeaderProps = {
  className?: string;
  isMobile: boolean;
  isSearchButtonExpanded?: boolean;
  isSticky: boolean;
  onSearchButtonClick?: () => void;
  shouldShowBackButton: boolean;
  shouldShowLoginButton: boolean;
  shouldShowSearchButton: boolean;
  withOnlyCenterAlignedLogo: boolean;
};

const Header = ({
  className,
  isMobile,
  isSearchButtonExpanded,
  isSticky,
  onSearchButtonClick,
  shouldShowBackButton,
  shouldShowLoginButton,
  shouldShowSearchButton,
  withOnlyCenterAlignedLogo
}: HeaderProps): JSX.Element => {
  const {t} = useTranslation();
  const {data: userData} = useUserData();
  const {pickBackendUrl} = useBackendValue();
  const scrollDirection = useWindowScrollDirection();

  const employersPage = pickBackendUrl('employersPage');

  const shouldShowLanguagePicker =
    !withOnlyCenterAlignedLogo && shouldShowLoginButton && !userData && hasLanguageOptions();

  const shouldShowForEmployersLinks = !withOnlyCenterAlignedLogo && !!employersPage;

  const shouldShowUserButton = FEATURE_FLAGS.IS_PROFILE_ENABLED && !withOnlyCenterAlignedLogo && shouldShowLoginButton;

  const numberOfItemsShown = [shouldShowForEmployersLinks, shouldShowLanguagePicker, shouldShowUserButton].filter(
    Boolean
  ).length;

  const shouldShowBurgerMenu = isMobile && !withOnlyCenterAlignedLogo && numberOfItemsShown > 2;

  return (
    <header
      className={clsx(
        styles.header,
        {
          [styles.hidden]: isSticky && scrollDirection === ScrollDirection.DOWN,
          [styles.mobile]: isMobile,
          [styles.sticky]: isSticky,
          [styles.compact]: shouldShowForEmployersLinks
        },
        className
      )}
    >
      <div
        className={clsx(styles['content-container'], {
          [styles['center-aligned']]: withOnlyCenterAlignedLogo
        })}
      >
        {!withOnlyCenterAlignedLogo && shouldShowBackButton ? (
          <BackButton />
        ) : (
          <JobBoardLogo isLinkable isMobile={isMobile} logoAsset={window.jobBoard.assets.headerLogo} />
        )}
        <div className={styles['right-side-container']}>
          {shouldShowBurgerMenu ? (
            <>
              {!withOnlyCenterAlignedLogo && shouldShowSearchButton && (
                <SearchJobsButton
                  isMobile={isMobile}
                  isSearchButtonExpanded={isSearchButtonExpanded}
                  onSearchButtonClick={onSearchButtonClick}
                />
              )}
              <BurgerMenu />
            </>
          ) : (
            <>
              {shouldShowForEmployersLinks && (
                <ButtonTertiary
                  className={styles['employers-link']}
                  data-ui="employers-link"
                  href={employersPage}
                  rel="noreferrer"
                  target="_self"
                >
                  {t('header.employers')}
                </ButtonTertiary>
              )}
              {!withOnlyCenterAlignedLogo && shouldShowSearchButton && (
                <SearchJobsButton
                  isMobile={isMobile}
                  isSearchButtonExpanded={isSearchButtonExpanded}
                  onSearchButtonClick={onSearchButtonClick}
                />
              )}
              {shouldShowLanguagePicker && <LanguagePicker isMobile={isMobile} />}
              {shouldShowUserButton && (
                <div className={styles.profile}>
                  {userData ? (
                    <Suspense fallback={null}>
                      <ProfileAvatar
                        isMobile={isMobile}
                        shouldShowNewProfileIndication={
                          !userData.hasExploredProfile || !!userData.unverifiedStructuredProfile
                        }
                        url={userData.avatar}
                      />
                    </Suspense>
                  ) : (
                    <LoginButton isMobile={isMobile} />
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
