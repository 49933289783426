import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import symbols from 'shared/ui/symbols';
import ListItemContainer from '../listItem/container';
import getRandomString from 'shared/ui/helpers/getRandomString';
import KeyboardHandlers from 'shared/ui/behaviors/keyboardHandler';
import propsFilter from 'shared/ui/helpers/propsFilter';
import isEmpty from 'lodash/isEmpty';
import styles from './styles.scss';

const DIRECTIONS = KeyboardHandlers.constants.DIRECTIONS;
export const NAVIGATION_MEDIUM = {
  MOUSE: 'mouse',
  KEYBOARD: 'kb'
};
const NOOP_FN = () => undefined;
const arrayRange = (start, end) => Array.from({length: end - start + 1}, (v, k) => k + start);

const isScrollable = element => {
  const overflowY = window.getComputedStyle(element).overflowY;

  const isScrollableY = (overflowY === 'auto' || overflowY === 'scroll') && element.scrollHeight > element.clientHeight;

  return isScrollableY;
};

const findClosestScrollableAncestor = element => {
  while (element) {
    if (isScrollable(element)) {
      return element;
    }

    element = element.parentElement;
  }

  return null;
};

const serializeOptions = options => {
  const getListItemId = o => `${o.value}`;

  return options
    .map(option => {
      const optionId = getListItemId(option);
      if (option.options) {
        return [optionId, ',', ...serializeOptions(option.options)].join('');
      }
      return optionId;
    })
    .join('|');
};

const getVisibleItems = items => {
  const getVisibleItem = item => {
    if (!item) {
      return null;
    }
    if (item.options) {
      const visibleOptions = getVisibleItems(item.options);
      if (item.visible === false && visibleOptions.length > 0) {
        return visibleOptions;
      }
      if (item.visible === false && visibleOptions.length === 0) {
        return null;
      }
      return [
        {
          ...item,
          options: visibleOptions.length > 0 ? visibleOptions : []
        }
      ];
    }
    if (item.visible === false) {
      return null;
    }
    return [{...item}];
  };

  const visibleItems = items.reduce((acc, item) => {
    const visibleItem = getVisibleItem(item);
    if (visibleItem) {
      return [...acc, ...visibleItem];
    }
    return acc;
  }, []);

  return visibleItems;
};

class ListBox extends React.Component {
  state = {
    optionsId: '',
    activeElement: undefined,
    activeElementIndex: -1,
    isKeyboardControlled: false,
    canScrollDown: false
  };

  static getDerivedStateFromProps({focusFirst, items}, {prevFocusFirst, optionsId: prevOptionsId}) {
    const differentiationOnFocusFirst = prevFocusFirst !== focusFirst;
    const optionsId = serializeOptions(items);
    const newOptionsAreDifferent = optionsId !== prevOptionsId;

    if (differentiationOnFocusFirst || newOptionsAreDifferent) {
      return {
        activeElement: undefined,
        activeElementIndex: -1,
        prevFocusFirst: differentiationOnFocusFirst ? focusFirst : prevFocusFirst,
        optionsId: newOptionsAreDifferent ? optionsId : prevOptionsId
      };
    }

    return null;
  }

  componentDidMount() {
    if (this.props.showScrollingShadow && this.ulRef.current) {
      const listContainer = this.getScrollableContainer();

      if (listContainer && listContainer.nodeType === Node.ELEMENT_NODE) {
        this.updateScrollIndicator(listContainer);

        this.listObserver = new MutationObserver(() => {
          this.updateScrollIndicator(listContainer);
        });

        this.listObserver.observe(listContainer, {
          attributes: true,
          childList: false,
          subtree: true,
          attributeFilter: ['aria-expanded']
        });
      }
    }
    if (this.props.focusFirst) {
      this.setInitialActive();

      if (this.searchInputRef.current) {
        this.searchInputRef.current.addEventListener('focus', this.setPseudoFocusToActiveElement);
      } else if (this.ulRef.current) {
        this.ulRef.current.addEventListener('focus', this.setPseudoFocusToActiveElement);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const optionsHaveChanged = prevState.optionsId !== this.state.optionsId;

    this.calculatedItemsTopPosition = {};
    this.calculatedItemsHeight = {};

    if (optionsHaveChanged && this.props.showScrollingShadow && this.ulRef.current) {
      const listContainer = this.getScrollableContainer();
      if (listContainer) {
        this.updateScrollIndicator(listContainer);
      }
    }

    if (this.props.focusFirst && optionsHaveChanged) {
      this.requestAnimationFrame(() => {
        this.requestAnimationFrame(() => this.setInitialActive());
      });
    }
  }

  componentWillUnmount() {
    this.animationFrames.forEach(animationFrame => window.cancelAnimationFrame(animationFrame));

    if (this.ulRef.current) {
      this.ulRef.current.removeEventListener('focus', this.setPseudoFocusToActiveElement);
    }

    if (this.listObserver) {
      this.listObserver.disconnect();
    }

    if (this.searchInputRef.current) {
      this.searchInputRef.current.removeEventListener('focus', this.setPseudoFocusToActiveElement);
    }
  }

  // DO NOT REMOVE
  // Exposed method to set focus to ul or search-input element
  focus() {
    if (this.searchInputRef.current && this.searchInputRef.current.focus) {
      this.searchInputRef.current.focus();
    } else if (this.ulRef.current && this.ulRef.current.focus) {
      this.ulRef.current.focus();
    }
  }

  setPseudoFocusToActiveElement = () => {
    const [selectedIndex] = this.getSelectedIndex(this.props.items);

    if (typeof selectedIndex === 'number' && selectedIndex > -1) {
      this.focusOption(selectedIndex);
    }
  };

  optionElements = [];
  randomId = getRandomString();
  ulRef = React.createRef();
  searchInputRef = React.createRef();
  animationFrames = new Set();

  // the actual position of the items in the list
  calculatedItemsTopPosition = {};
  // the actual height of the items in the list
  calculatedItemsHeight = {};
  // the scroll distance
  scrollTop = 0;

  requestAnimationFrame = handler => {
    let animationFrame = null;

    animationFrame = window.requestAnimationFrame(() => {
      this.animationFrames.delete(animationFrame);
      handler();
    });

    this.animationFrames.add(animationFrame);
  };

  setInitialActive() {
    const [selectedIndex] = this.getSelectedIndex(this.props.items);

    const nextActiveIndex = selectedIndex !== -1 ? selectedIndex : this.getNextFocused(1);

    this.setActive(nextActiveIndex);
  }

  setActive(index, medium = NAVIGATION_MEDIUM.MOUSE) {
    let element = this.getDomElementByIndex(index);

    if (!element) {
      index = this.getNextFocused(1);
      element = this.getDomElementByIndex(index);
    }

    if (element) {
      this.setState({
        activeElement: element,
        activeElementIndex: index,
        isKeyboardControlled: medium === NAVIGATION_MEDIUM.KEYBOARD
      });

      if (this.props.onUpdateActiveDescendant) {
        const activeId = this.getElementId(element);

        this.props.onUpdateActiveDescendant(activeId, medium);
      }
    }
  }

  // DO NOT REMOVE
  // Exposed method to set active descendant programmatically from parent
  setActiveDescendant(index) {
    let element = this.getDomElementByIndex(index);

    if (!element) {
      index = this.getNextFocused(1);
      element = this.getDomElementByIndex(index);
    }

    if (element) {
      this.setState({
        activeElement: element,
        activeElementIndex: index,
        isKeyboardControlled: false
      });
    }
  }

  // DO NOT REMOVE
  // Exposed method to set active descendant programmatically from parent
  clearActiveDescendant() {
    this.setState({
      activeElement: undefined,
      activeElementIndex: -1,
      isKeyboardControlled: false
    });
  }

  getSelectedIndex(options, accumulatedIndex = 0) {
    let selected = -1;
    let totalIndex = accumulatedIndex;
    options.find(option => {
      if (option.visible === false) {
        return false;
      }
      if (option.selected) {
        selected = totalIndex;
        return true;
      }
      if (option.options) {
        const [result, totalIndexWithNestedCounted] = this.getSelectedIndex(option.options, totalIndex + 1);
        if (result !== -1) {
          selected = result;
          return true;
        }
        totalIndex = totalIndexWithNestedCounted;
        return false;
      }
      totalIndex = totalIndex + 1;
      return false;
    });

    return [selected, totalIndex];
  }

  getActiveElement() {
    if (this.props.virtualized) {
      const activeIndex = this.getActiveIndex();
      return this.getDomElementByIndex(activeIndex);
    }

    return this.state.activeElement;
  }

  getScrollableContainer() {
    if (!this.ulRef.current) {
      return;
    }

    return this.props.virtualized ? this.ulRef.current.parentElement : this.ulRef.current;
  }

  getActiveIndex() {
    return this.state.activeElementIndex;
  }

  getElementId(element) {
    return element.getAttribute('id');
  }

  getActiveDescendant() {
    if (this.props.virtualized) {
      const activeElement = getVisibleItems(this.props.items)[this.getActiveIndex()];
      if (!activeElement) {
        return;
      }

      const activeElementValue = activeElement.value;
      return this.getListItemId(activeElementValue);
    }

    const activeElement = this.getActiveElement();
    return activeElement && this.getElementId(activeElement);
  }

  assignOptionRef(el) {
    if (!el || el.dataset.visible === 'false') {
      return;
    }

    const index = Number(el.getAttribute('data-index'));
    if (this.props.dynamicItemHeight && !isNaN(index)) {
      this.calculatedItemsHeight[index] = el.clientHeight;

      if (index === 0) {
        this.calculatedItemsTopPosition[index] = 0;
      }

      if (index > 0) {
        if (
          this.calculatedItemsTopPosition[index - 1] !== undefined &&
          this.calculatedItemsHeight[index - 1] !== undefined
        ) {
          const top = this.calculatedItemsTopPosition[index - 1] + this.calculatedItemsHeight[index - 1];

          this.calculatedItemsTopPosition[index] = top;

          el.style.top = `${top}px`;
        } else {
          this.calculatedItemsTopPosition[index] = el.offsetTop;
        }
      }
    }

    this.optionElements.push(el);
  }

  assignUlRef(el) {
    this.ulRef.current = el;
    if (!el) {
      return;
    }
    if (this.props.virtualized) {
      el.style.height = `${this.getVirtualizedListHeight()}px`;
    }
  }

  getElementDataIndex(element) {
    const dataIndex = element && element.getAttribute('data-index');
    return parseInt(dataIndex, 10);
  }

  getDomElementByIndex(index) {
    if (this.props.virtualized) {
      return this.optionElements.find(el => this.getElementDataIndex(el) === index);
    }

    return this.optionElements[index];
  }

  getParentListboxElement(element) {
    return findClosestScrollableAncestor(element);
  }

  isInView(element) {
    const parentListboxElement = this.getParentListboxElement(element);

    if (parentListboxElement) {
      const {top: parentTop, bottom: parentBottom} = parentListboxElement.getBoundingClientRect();
      const {top: elementTop, bottom: elementBottom} = element.getBoundingClientRect();

      if (
        elementTop <= parentBottom &&
        elementTop >= parentTop &&
        elementBottom <= parentBottom &&
        elementBottom >= parentTop
      ) {
        return true;
      }
    }

    return false;
  }

  focusOption(option, medium) {
    const element = this.getDomElementByIndex(option);

    if (element) {
      this.setActive(option, medium);

      if (!this.isInView(element)) {
        if (typeof element.scrollIntoView === 'function') {
          const prefersReducedMotion = window.matchMedia?.('(prefers-reduced-motion: reduce)').matches;

          element.scrollIntoView({
            behavior: prefersReducedMotion || this.props.disableSmoothScrolling ? 'auto' : 'smooth',
            block: 'nearest',
            inline: 'nearest'
          });
        } else {
          // Fallback when scrollIntoView is not supported
          const previousActiveIndex = this.getActiveIndex();
          const alignToTop = option < previousActiveIndex;
          const liHeight = element.offsetHeight;
          const ulHeight = element.parentNode.scrollHeight;

          const elementThatDefinesListHeight = this.props.virtualized
            ? element.parentElement.parentElement
            : element.parentElement;

          const currentScrollTop =
            previousActiveIndex * liHeight - (alignToTop ? 0 : elementThatDefinesListHeight.offsetHeight - liHeight);

          const ulScrollTop = currentScrollTop + (alignToTop ? -liHeight : liHeight);

          if (option === 0) {
            elementThatDefinesListHeight.scrollTop = 0;
            return;
          }

          if (option === this.getLastElementIndex()) {
            elementThatDefinesListHeight.scrollTop = ulHeight;
            return;
          }

          elementThatDefinesListHeight.scrollTop = ulScrollTop;
        }
      }
    }
  }

  getLastElementIndex() {
    return this.getListItemsLength() - 1;
  }

  getListItemsLength() {
    return this.props.virtualized ? this.getAllVisibleItemsLength(this.props.items) : this.optionElements.length;
  }

  // Ensure active index is correct in case of nested options have changed
  getActiveElementComparingElementAndState() {
    const activeIndexFromState = this.getActiveIndex();

    if (this.props.virtualized) {
      return activeIndexFromState;
    }

    const activeIndexFromOptionElements = this.optionElements.findIndex(el => el === this.state.activeElement);
    const activeElementIndexHasChanged = activeIndexFromState !== activeIndexFromOptionElements;

    if (activeElementIndexHasChanged) {
      const newActiveElementIndex = this.optionElements.findIndex(
        el => el.getAttribute('value') === this.state.activeElement.getAttribute('value')
      );

      if (newActiveElementIndex !== -1) {
        return newActiveElementIndex;
      }
    }

    return activeIndexFromState;
  }

  getNextFocused(step = 0, skippedPresentationalFocused = null) {
    const activeIndex = this.getActiveElementComparingElementAndState();
    const currentActive = activeIndex !== '' ? activeIndex : -1;
    const listLength = this.getListItemsLength();
    const isPresentational = el => el && el.hasAttribute('data-presentational');
    const isHidden = el => {
      if (!el || !this.ulRef) {
        return false;
      }

      const ulElement = el.closest('ul');

      if (ulElement.getAttribute('role') !== 'group' && !ulElement.id) {
        return false;
      }

      const headerEl = this.ulRef.current.querySelector(`[aria-owns="${ulElement.id}]"`);

      return headerEl && headerEl.getAttribute('aria-expanded') === 'false';
    };

    let nextFocused = (skippedPresentationalFocused !== null ? skippedPresentationalFocused : currentActive) + step;

    if (nextFocused < 0) {
      nextFocused = this.getLastElementIndex();
    }

    if (nextFocused >= listLength) {
      nextFocused = 0;
    }

    if (isHidden(this.getDomElementByIndex(nextFocused)) || isPresentational(this.getDomElementByIndex(nextFocused))) {
      if (listLength <= nextFocused + step) {
        return -1;
      }
      nextFocused = this.getNextFocused(step, nextFocused);
    }

    return nextFocused;
  }

  handleUpDown = (event, step) => {
    event.preventDefault();
    event.stopPropagation();

    const nextFocused = this.getNextFocused(step);

    this.focusOption(nextFocused, NAVIGATION_MEDIUM.KEYBOARD);
  };

  handleArrows = (event, {direction}) => {
    const {handleArrows, isHorizontalList} = this.props;

    if (isHorizontalList) {
      switch (direction) {
        case DIRECTIONS.UP:
        case DIRECTIONS.LEFT:
          return this.handleUpDown(event, -1);
        case DIRECTIONS.DOWN:
        case DIRECTIONS.RIGHT:
          return this.handleUpDown(event, 1);
        default:
          if (typeof handleArrows === 'function') {
            handleArrows(event, {direction, element: this.getActiveElement(), focus: this.getNextFocused});
          }
      }
      return;
    }

    switch (direction) {
      case DIRECTIONS.UP:
        return this.handleUpDown(event, -1);
      case DIRECTIONS.DOWN:
        return this.handleUpDown(event, 1);
      default:
        if (typeof handleArrows === 'function') {
          handleArrows(event, {direction, element: this.getActiveElement(), focus: this.getNextFocused});
        }
    }
  };

  handleMouseEnterInOption = mouseEvent => {
    const optionHovered = mouseEvent.currentTarget;
    const hoveredIndexInListbox = this.props.virtualized
      ? this.getElementDataIndex(optionHovered)
      : this.optionElements.indexOf(optionHovered);

    if (hoveredIndexInListbox === -1) {
      return;
    }

    this.requestAnimationFrame(() => this.focusOption(hoveredIndexInListbox, NAVIGATION_MEDIUM.MOUSE));
  };

  handleMouseLeavesListbox = () => {
    const hasActiveElement = !isNaN(parseFloat(this.getActiveIndex()));
    if (!hasActiveElement) {
      return;
    }
    this.setState({
      activeElement: undefined,
      activeElementIndex: -1,
      isKeyboardControlled: false
    });
  };

  handleMouseMoveInsideListbox = mouseEvent => {
    if (!this.state.isKeyboardControlled) {
      return;
    }

    this.requestAnimationFrame(() => this.focusOption(this.getActiveIndex(), NAVIGATION_MEDIUM.MOUSE));

    return mouseEvent;
  };

  selectOptionAndPreventDefault = event => {
    if (this.getAllVisibleItemsLength(this.props.items)) {
      event.preventDefault();
      this.handleSelectedByKeyboard(event, true);
    }
  };

  handleSelectedByKeyboardAndStopPropagation = event => {
    event.stopPropagation();
    this.handleSelectedByKeyboard(event);
  };

  handleSelectedByKeyboard = (event, stopPropagation = false) => {
    const activeElement = this.getActiveElement();
    if (activeElement && this.getAllVisibleItemsLength(this.props.items)) {
      if (stopPropagation && typeof event.stopPropagation === 'function') {
        event.stopPropagation();
      }

      const isDisabled = activeElement.hasAttribute('disabled');
      const isPresentational = activeElement.hasAttribute('data-presentational');
      if (isDisabled || isPresentational) {
        event.stopPropagation();
        return;
      }
      this.props.onSelect(activeElement, event);
    }
  };

  handleSelectedByClick = event => {
    const selectedElement = event.target.hasAttribute('value') ? event.target : event.currentTarget;
    const isLink = !!selectedElement.querySelector('a[href]');

    const isDisabled = selectedElement.hasAttribute('disabled');
    const isPresentational = selectedElement.hasAttribute('data-presentational');

    // Do not preventDefault to allow handle the link natively
    if (isPresentational && isLink) {
      return;
    }

    event.preventDefault();

    if (isDisabled || isPresentational) {
      event.stopPropagation();
      return;
    }

    this.props.onSelect(selectedElement, event);
  };

  getListItemId(value) {
    return `${this.randomId}_${value}`;
  }

  isActiveItem(index, id) {
    if (this.props.virtualized) {
      return this.state.activeElementIndex === index;
    }

    const activeElement = this.getActiveElement();
    return (activeElement && activeElement.id) === id;
  }

  mapOptionsToListItems = (options, depth = 0) => {
    const {Item, ariaType, onSelect, virtualized, disableSelectOnClick} = this.props;
    const ariaRole = ariaType[1];
    const isSetOnSelectHandler = typeof onSelect === 'function' && onSelect !== NOOP_FN;

    return options.map((option, key) => {
      const isGroup = option.options instanceof Array;
      const listItemId = this.getListItemId(option.value);
      const groupId = isGroup ? `${this.randomId}_group_${key}_${depth}` : undefined;

      const dataAndAriaAttributes = propsFilter(option).ariaAttributes().dataAttributes().getFiltered();

      // Make group header not selectable by default
      const isPresentational = (isGroup && option.presentational !== false) || option.presentational;
      const isVisible = typeof option.visible === 'undefined' ? true : option.visible;
      const itemIndex = parseInt(option.realIndex || key, 10);

      const listItem = option.display ? (
        <ListItemContainer
          id={listItemId}
          optionRef={this.assignOptionRef.bind(this)}
          key={`${depth}_${itemIndex}${option.value}`} // use itemIndex & value at key to avoid changing key for the same child when virtualized
          role={isPresentational ? 'presentation' : ariaRole}
          value={option.value}
          disabled={option.disabled}
          presentational={isPresentational}
          selected={option.selected}
          expanded={option.expanded}
          data-visible={isVisible}
          data-index={this.props.virtualized ? itemIndex : undefined}
          data-depth-level={depth}
          aria-owns={groupId}
          aria-setsize={this.props.virtualized ? this.getAllVisibleItemsLength(this.props.items) : undefined}
          aria-posinset={this.props.virtualized ? itemIndex + 1 : undefined}
          onMouseEnter={isPresentational ? null : this.handleMouseEnterInOption}
          {...(isSetOnSelectHandler && !disableSelectOnClick ? {onClick: this.handleSelectedByClick} : {})}
          {...dataAndAriaAttributes}
          style={virtualized ? option.virtualizedStyle : undefined}
        >
          <Item
            visible={isVisible}
            role="presentation"
            display={option.display}
            disabled={option.disabled}
            selected={option.selected}
            search={option.search}
            tooltip={option.tooltip}
            collapsible={option.collapsible}
            expanded={option.expanded}
            virtualized={virtualized}
            active={this.isActiveItem(itemIndex, listItemId)}
            data-active={this.isActiveItem(itemIndex, listItemId)}
            group={isGroup}
          />
        </ListItemContainer>
      ) : null;

      return isGroup ? (
        <React.Fragment key={key}>
          {listItem}
          {option.options.length > 0 && (
            <ul id={groupId} key={groupId} role="group">
              {this.mapOptionsToListItems(option.options, depth + 1)}
            </ul>
          )}
        </React.Fragment>
      ) : (
        listItem
      );
    });
  };

  handleScrollOnListContainer = event => {
    if (this.props.onScroll) {
      this.props.onScroll(event);
    }
    this.scrollTop = event.currentTarget.scrollTop;

    this.updateScrollIndicator(event.currentTarget);
  };

  getAllVisibleItemsLength(items) {
    let total = 0;
    const addItem = item => {
      if (item.visible !== false) {
        total = total + 1;
      }
      if (item.options) {
        item.options.forEach(addItem);
      }
      return total;
    };

    items.forEach(addItem);

    return total;
  }

  getVirtualizedListHeight() {
    const {virtualizedItemHeight, dynamicItemHeight, addOption, items} = this.props;
    const allItemsLength = this.getAllVisibleItemsLength(items) + (addOption ? 1 : 0);
    const estimationOfListHeightBasedOnNumberOfItems = allItemsLength * virtualizedItemHeight;

    if (!dynamicItemHeight) {
      return estimationOfListHeightBasedOnNumberOfItems;
    }

    const indexesOfCalculatedItems = Object.keys(this.calculatedItemsTopPosition);

    if (allItemsLength <= indexesOfCalculatedItems.length) {
      return indexesOfCalculatedItems
        .map(index => this.calculatedItemsHeight[index])
        .reduce((acc, height) => acc + height, 0);
    }

    const lastItemIndex = allItemsLength - 1;

    const lastWindowedIndex = indexesOfCalculatedItems[indexesOfCalculatedItems.length - 2];
    const lastCalculatedItemIndex = indexesOfCalculatedItems[indexesOfCalculatedItems.length - 1];

    if (
      (this.calculatedItemsTopPosition[lastWindowedIndex] === undefined,
      this.calculatedItemsHeight[lastWindowedIndex] === undefined,
      this.calculatedItemsHeight[lastCalculatedItemIndex] === undefined)
    ) {
      return estimationOfListHeightBasedOnNumberOfItems;
    }

    const estimationOfListHeightBasedOnRenderedItems =
      this.calculatedItemsTopPosition[lastWindowedIndex] +
      this.calculatedItemsHeight[lastWindowedIndex] +
      (lastItemIndex - 1 - lastWindowedIndex) * virtualizedItemHeight +
      this.calculatedItemsHeight[lastCalculatedItemIndex];

    return Math.max(estimationOfListHeightBasedOnNumberOfItems, estimationOfListHeightBasedOnRenderedItems);
  }

  getUlStylesWhenVirtualized() {
    return {
      height: `${this.getVirtualizedListHeight()}px`
    };
  }

  getItemsWithRenderProps(items) {
    const {virtualizedItemHeight, dynamicItemHeight} = this.props;
    let index = 0;

    const getItem = item => {
      const newItem = {};

      const estimatedItemTopPosition = index * virtualizedItemHeight;

      const currentItemTopPosition =
        dynamicItemHeight && this.calculatedItemsTopPosition[index] !== undefined
          ? this.calculatedItemsTopPosition[index]
          : estimatedItemTopPosition;

      const currentItemHeight = dynamicItemHeight ? this.calculatedItemsHeight[index] : virtualizedItemHeight;

      const virtualizedStyle = {
        top: `${currentItemTopPosition}px`,
        height: `${currentItemHeight}px`
      };
      newItem.virtualizedStyle = virtualizedStyle;

      if (item.visible === false) {
        return {...item, ...newItem, realIndex: undefined};
      }

      newItem.realIndex = index;

      index = index + 1;
      if (item.options) {
        newItem.options = item.options.map(subItem => getItem(subItem));
      }
      return {...item, ...newItem};
    };

    return items.map(getItem);
  }

  getActiveIndexFromScrollTop() {
    const allItemsLength = this.getAllVisibleItemsLength(this.props.items);
    return Object.keys(this.calculatedItemsTopPosition).find(rowIndex => {
      return this.calculatedItemsTopPosition[rowIndex] > this.scrollTop && Number(rowIndex) !== allItemsLength - 1;
    });
  }

  getWindowingStartingIndex() {
    const scrollTop = this.scrollTop;
    const {virtualizedOverscan, virtualizedItemHeight, dynamicItemHeight} = this.props;

    if (!dynamicItemHeight) {
      return Math.max(0, Math.floor(scrollTop / virtualizedItemHeight) - virtualizedOverscan);
    }

    let scrollTopIsAtIndex = this.getActiveIndexFromScrollTop();

    if (scrollTopIsAtIndex === undefined) {
      scrollTopIsAtIndex = Math.floor(scrollTop / virtualizedItemHeight);
    }

    return Math.max(0, scrollTopIsAtIndex - 1 - virtualizedOverscan);
  }

  getWindowingEndingIndex(startFromIndex = 0) {
    const scrollTop = this.scrollTop;
    const {virtualizedOverscan, virtualizedItemHeight, virtualizedListHeight, dynamicItemHeight, items} = this.props;

    const allItemLength = this.getAllVisibleItemsLength(items);
    const lastIndex = allItemLength - 1;

    if (!dynamicItemHeight) {
      return Math.min(
        lastIndex,
        Math.floor((scrollTop + virtualizedListHeight) / virtualizedItemHeight) + virtualizedOverscan
      );
    }

    const activeIndexFromScrollTop = Number(this.getActiveIndexFromScrollTop()) || startFromIndex;
    const numberOfListItemsFitsVisibleWindow = Math.floor(virtualizedListHeight / virtualizedItemHeight);

    return Math.min(lastIndex, activeIndexFromScrollTop + numberOfListItemsFitsVisibleWindow + virtualizedOverscan);
  }

  getRenderedItems() {
    const {items, virtualized} = this.props;

    if (!virtualized) {
      return items;
    }

    const allItemLength = this.getAllVisibleItemsLength(items);

    const [selectedIndex] = this.getSelectedIndex(items);
    const lastIndex = allItemLength - 1;
    const startFromIndex = this.getWindowingStartingIndex();
    const endInIndex = this.getWindowingEndingIndex(startFromIndex);
    const rangeOfIndexes = arrayRange(startFromIndex, endInIndex);

    // Add selected index to list of rendered items
    // Needed for cases where the selected item is out of the visual list window
    if (!rangeOfIndexes.includes(selectedIndex)) {
      rangeOfIndexes.push(selectedIndex);
    }

    // Render last item to navigate with keyboard from first item to the last one
    if (startFromIndex === 0 && !rangeOfIndexes.includes(lastIndex)) {
      rangeOfIndexes.push(lastIndex);
    }

    // Render always the first item as it is needed for navigation from last item to first one
    // but also when search query changes and active item is removed from dom
    if (!rangeOfIndexes.includes(0)) {
      rangeOfIndexes.unshift(0);
    }

    const filterItemsWithIndexes = _items => {
      const renderItems = [];

      for (const item of _items) {
        if (item.options) {
          item.options = filterItemsWithIndexes(item.options);
        }
        if (rangeOfIndexes.includes(item.realIndex) || (item.options && item.options.length > 0)) {
          renderItems.push(item);
        }
      }
      return renderItems;
    };

    return filterItemsWithIndexes(this.getItemsWithRenderProps(items));
  }

  // DO NOT REMOVE
  // Exposed method to update the scroll indicator from parent
  updateScrollIndicator(element) {
    if (!element) {
      return;
    }
    const {scrollTop, scrollHeight, clientHeight} = element;
    const canScrollDown = scrollTop + clientHeight < scrollHeight;
    this.setState({canScrollDown});
  }

  handleScrollOnList = event => {
    if (this.props.onScroll) {
      this.props.onScroll(event);
    }
    if (!this.props.showScrollingShadow && this.props.virtualized) {
      return;
    }
    this.updateScrollIndicator(event.currentTarget);
  };

  renderList() {
    const {
      items,
      labelId,
      emptyItem,
      addOption,
      ariaType,
      listWrapper,
      id,
      virtualized,
      virtualizedListHeight,
      showScrollingShadow,
      ...props
    } = this.props;
    const ulAriaActiveDescendant = this.getActiveDescendant();

    const ariaAndDataAttributes = propsFilter(props).ariaAttributes().dataAttributes().getFiltered();
    const touchAndMouseEvents = propsFilter(props)
      .like(/onTouch|onMouse/)
      .getFiltered();

    const visibleItemsLength = this.getAllVisibleItemsLength(items);

    const isVirtualizedAndHasItems = virtualized && visibleItemsLength + (addOption ? 1 : 0) > 0;

    const renderedItems = this.getRenderedItems();

    const ul = (
      <ul
        ref={this.assignUlRef.bind(this)}
        role={ariaType[0]}
        tabIndex="-1"
        aria-labelledby={labelId}
        aria-activedescendant={this.props.onUpdateActiveDescendant ? undefined : ulAriaActiveDescendant}
        onMouseMove={this.state.isKeyboardControlled ? this.handleMouseMoveInsideListbox : undefined}
        onMouseLeave={ariaType[0] === ListBox.constants.TYPE.MENU[0] ? this.handleMouseLeavesListbox : undefined}
        id={id}
        data-virtualized-listbox={virtualized}
        {...ariaAndDataAttributes}
        {...(!isEmpty(touchAndMouseEvents) && touchAndMouseEvents)}
        className={clsx(
          {
            [styles.list]: true,
            [styles.keyboard]: this.state.isKeyboardControlled,
            [styles.virtualized]: isVirtualizedAndHasItems
          },
          ariaAndDataAttributes.className
        )}
        onScroll={this.handleScrollOnList}
      >
        {this.mapOptionsToListItems(renderedItems)}
        {visibleItemsLength === 0 && (
          <ListItemContainer
            disabled
            optionRef={this.assignOptionRef.bind(this)}
            data-index="0"
            data-role="empty-list-item"
          >
            {emptyItem}
          </ListItemContainer>
        )}
        {addOption && (
          <ListItemContainer
            disabled
            data-index={visibleItemsLength ? visibleItemsLength : 1}
            optionRef={this.assignOptionRef.bind(this)}
            data-role="add-option"
          >
            {addOption}
          </ListItemContainer>
        )}
      </ul>
    );

    const list = virtualized ? (
      <div
        className={clsx({
          [styles.virtualized]: virtualized,
          [styles['list-container']]: true
        })}
        onScroll={this.handleScrollOnListContainer}
        data-role="list-container"
        style={{maxHeight: `${virtualizedListHeight}px`}}
      >
        {ul}
      </div>
    ) : (
      ul
    );

    return React.cloneElement(listWrapper, {children: list, 'data-role': 'list-wrapper'});
  }

  render() {
    const {children, selectOnTab, selectOnSpace, className, style, onSelect, listWrapper, showScrollingShadow} =
      this.props;
    const itemsAreSelectable = onSelect !== NOOP_FN;
    this.optionElements = [];

    const handleLoad = el => {
      this.searchInputRef.current = el;

      if (!Array.isArray(children) && typeof children?.props?.onLoad === 'function') {
        children?.props?.onLoad(el);
      }
    };

    return (
      <KeyboardHandlers
        handleSpacePressed={itemsAreSelectable && selectOnSpace ? this.selectOptionAndPreventDefault : NOOP_FN}
        handleEnterPressed={itemsAreSelectable ? this.selectOptionAndPreventDefault : NOOP_FN}
        handleArrowsPressed={this.handleArrows}
        {...(itemsAreSelectable && selectOnTab
          ? {handleTabPressed: this.handleSelectedByKeyboardAndStopPropagation}
          : {})}
      >
        <div
          style={style}
          className={clsx(
            {
              [styles['custom-wrapper']]: listWrapper.type !== React.Fragment,
              [styles['has-scroll-indicator']]: showScrollingShadow && this.state.canScrollDown
            },
            className
          )}
        >
          {children ? React.cloneElement(children, {onLoad: handleLoad}) : null}
          {this.renderList()}
        </div>
      </KeyboardHandlers>
    );
  }
}

ListBox.constants = {
  TYPE: {
    LISTBOX: ['listbox', 'option'],
    LIST: ['list', 'listitem'],
    MENU: ['menu', 'menuitem'],
    TREE: ['tree', 'treeitem']
  }
};

ListBox.propTypes = {
  /** The element's id of the label. Will be assigned as aria-labelledby attribute. */
  labelId: PropTypes.string,
  /** The function that will be executed when active item is changed, with new active item id as parameter. */
  onUpdateActiveDescendant: PropTypes.func,
  /**
   * The handler for list item selection. If none passed the click handler won't be triggered allowing click events
   * to be handled on each of the `items` passed, given these are react components capable of handling its own
   * `onClick`
   */
  onSelect: PropTypes.func,
  /** Items to be rendered. */
  items: PropTypes.arrayOf(
    /** Array of objects. eg. [{value: 'value', display: 'Display', selected: true, disabled: false}] */
    PropTypes.shape({
      /** The value of the item. */
      value: PropTypes.string,
      /** The display text (description) of the item or any react component to render inside each `li`. */
      display: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object]),
      /** Whether the item is selected or not. */
      selected: PropTypes.bool,
      /** Whether the item is disabled or not. If disabled it cannot be selected. */
      disabled: PropTypes.bool,
      /** This item is used for presentational purposes only. */
      presentational: PropTypes.bool,
      /** The search term used to active highlighting in ListItems like `WithAvatar`. */
      search: PropTypes.string,
      /** Pass nested options for the specific item in order to render them as group */
      options: PropTypes.array
    })
  ),
  /**
   *  Modifies the aria properties of the list and its items. Should be an array where
   * first element is the aria role of the list and second element the aria role of each item.
   * The available types can be found under `Constructor.constants.TYPE[LIST|MENU|LISTBOX]`
   */
  ariaType: PropTypes.oneOf([
    ListBox.constants.TYPE.MENU,
    ListBox.constants.TYPE.LIST,
    ListBox.constants.TYPE.LISTBOX,
    ListBox.constants.TYPE.TREE
  ]),
  /** The component that defines listItem's styling. */
  Item: PropTypes.elementType.isRequired,
  /** The JSX that will be rendered as placeholder item when no result is available. */
  emptyItem: PropTypes.element,
  /** Aria attributes that should be applied to listbox. */
  ariaAttributes: PropTypes.object,
  /** When true, selects the active element upon TAB press. Defaults to `false` */
  selectOnTab: PropTypes.bool,
  /** When true, selects the active element upon SPACE press. Defaults to `false` */
  selectOnSpace: PropTypes.bool,
  /** The wrapper of the list. Default: `React.Fragment` */
  listWrapper: PropTypes.element,
  /** Controls whether the first item of the list should be focused. */
  focusFirst: PropTypes.bool,
  /** Controls whether the list is virtualized. */
  virtualized: PropTypes.bool,
  /** Adds support for virtualized with items of different height. */
  dynamicItemHeight: PropTypes.bool,
  /** The height of each list-item for virtualized list. Default: 36 */
  virtualizedItemHeight: PropTypes.number,
  /** The height of virtualized list we want to be visible. Default: 210 */
  virtualizedListHeight: PropTypes.number,
  /** The number of non-visible list-items above and below the visible window. Default: 2 */
  virtualizedOverscan: PropTypes.number,
  /** Whether the scrolling to the selected item (during focus) is animated or not. */
  disableSmoothScrolling: PropTypes.bool,
  /** Controls whether the list is horizontal in order to adjust listbox keyboard handling and accessibility. */
  isHorizontalList: PropTypes.bool,
  /** Controls if it will show a shadow at the bottom of the container when scrolling is available */
  showScrollingShadow: PropTypes.bool
};

ListBox.defaultProps = {
  onSelect: NOOP_FN,
  items: [],
  ariaType: ListBox.constants.TYPE.LISTBOX,
  selectOnTab: false,
  selectOnSpace: false,
  listWrapper: <React.Fragment />,
  virtualizedItemHeight: 36,
  virtualizedListHeight: 210,
  virtualizedOverscan: 2,
  showScrollingShadow: false
};

ListBox[symbols.ListBox] = true;

export default ListBox;
