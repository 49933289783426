import React from 'react';
import {Navigate, Outlet, useSearchParams} from 'react-router-dom';
import {routesPaths} from 'job-board/routes/constants';
import Loader from 'job-board/shared/components/loader';
import {useUserData} from 'job-board/shared/redux/userData/hooks';

export type ProtectedRouteProps = {
  children?: React.ReactElement;
  shouldRedirectWhenLoggedIn?: boolean;
};

const ProtectedRoute = ({children, shouldRedirectWhenLoggedIn}: ProtectedRouteProps) => {
  const [searchParams] = useSearchParams();
  const {data: userData, isUninitialized} = useUserData();

  if (isUninitialized) {
    return <Loader />;
  }

  if (shouldRedirectWhenLoggedIn ? userData : !userData) {
    return (
      <Navigate
        replace
        to={
          shouldRedirectWhenLoggedIn
            ? searchParams.get('redirect') || routesPaths.HOME
            : `${routesPaths.LOGIN}?redirect=${window.location.pathname}`
        }
      />
    );
  }

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
