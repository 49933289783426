import {useContext} from 'react';
import type {AnalyticsClient} from 'job-board/shared/analytics/clients/gtm';
import {AnalyticsContext} from './provider';

export const useAnalytics = (): AnalyticsClient => {
  const analytics = useContext(AnalyticsContext);
  if (typeof analytics === 'undefined') {
    throw new Error('useAnalytics must be used within a AnalyticsContext');
  }
  return analytics;
};
