import {Device} from 'shared/contexts/device';
import {mediaQueriesBuilder} from 'shared/contexts/device/responsive';

export const MOBILE_PORTRAIT_MAX_SIZE = 420;
export const MOBILE_LANDSCAPE_MIN_SIZE = 421;
export const MOBILE_LANDSCAPE_MAX_SIZE = 767;
export const TABLET_MIN_SIZE = 768;
export const TABLET_MAX_SIZE = 1024;
export const DESKTOP_MIN_SIZE = 1025;

export default mediaQueriesBuilder()
  .setLargerDevice(Device.Mobile)
  .addQueryWithSpecificity(Device.Desktop, [`(min-width: ${TABLET_MIN_SIZE}px)`]);
