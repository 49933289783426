const AUTO_AUTHENTICATE_FLOW = 'auto_authenticate';

export const authWithShield = (): Promise<void> => {
  return new Promise<void>(resolve => {
    const iframe = document.createElement('iframe');

    const exitSuccessfully = (): void => {
      iframe.remove();
      resolve();
    };

    const autoAuthFlowState = {authFlow: AUTO_AUTHENTICATE_FLOW};
    const authUrl = new URL('/auth/shield', window.location.origin);
    authUrl.searchParams.set('state', btoa(JSON.stringify(autoAuthFlowState)));

    iframe.onload = exitSuccessfully;
    iframe.onerror = exitSuccessfully;
    iframe.setAttribute('sandbox', 'allow-scripts');
    iframe.style.display = 'none';
    iframe.src = authUrl.toString();

    document.body.appendChild(iframe);
  });
};
