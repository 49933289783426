import i18n from 'job-board/i18n';

type LocalizedValue = string | {[key: string]: string};

export const makePageDataSelector =
  <BaseObject extends object, K extends keyof BaseObject>(object: BaseObject, language: string = i18n.language) =>
  (key?: K): string | undefined => {
    if (!key || object[key] === undefined) {
      return undefined;
    }

    const value = object[key] as LocalizedValue;

    return typeof value === 'string' ? value : value[language] || value.en;
  };
