import React from 'react';
import PropTypes from 'prop-types';
import getRandomString from 'shared/ui/helpers/getRandomString';

const ListItem = ({display, selected, optionRef, disabled, presentational, children, expanded, ...props}) => (
  <li
    {...props}
    ref={optionRef}
    aria-selected={selected}
    data-presentational={presentational || null}
    disabled={disabled}
    aria-disabled={disabled}
    aria-expanded={expanded}
  >
    {display || children}
  </li>
);

ListItem.defaultProps = {
  role: 'option',
  id: getRandomString(),
  selected: false
};

ListItem.displayName = 'ListBox.ListItem.Container';

ListItem.propTypes = {
  /** The id of the option. */
  id: PropTypes.string,
  /** The value of the option. This will not be displayed at the user. */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The description of the option that will be displayed at the user. */
  display: PropTypes.string,
  /** Disabled option. User is unable to select it. */
  disabled: PropTypes.bool,
  /** This option is selected or not. */
  selected: PropTypes.bool,
  /** This option is used for presentational purposes only. */
  presentational: PropTypes.bool,
  /** The aria role. */
  role: PropTypes.string,
  /** The callback ref for li element. */
  optionRef: PropTypes.func,
  /** This method is triggered when li is clicked. */
  onClick: PropTypes.func,
  /** This option is for expandable list items */
  expanded: PropTypes.bool
};

export default ListItem;
