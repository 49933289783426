import React from 'react';
import PropTypes from 'prop-types';
import FilteredListBox from '../filtered';
import {withTranslations, getEvergreenTranslations} from 'shared/ui/providers/translations';
import Search from './search';

class SearchableListBox extends React.Component {
  state = {
    searchValue: '',
    activedescendant: ''
  };

  static getDerivedStateFromProps({searchValue}) {
    if (searchValue !== undefined) {
      return {
        searchValue
      };
    }

    return null;
  }

  handleSearch = event => {
    const searchValue = event.target.value;

    this.setState({searchValue});

    if (typeof this.props.onSearch === 'function') {
      this.props.onSearch(searchValue);
    }
  };

  handleActiveDescendantUpdate = activedescendant => this.setState({activedescendant});

  handleArrowsPressed = event => {
    const {isHorizontalList} = this.props;

    if (!isHorizontalList && ['ArrowLeft', 'ArrowRight'].includes(event.key)) {
      event.stopPropagation();
    }
  };

  render() {
    const {texts: _texts, onSearch, searchValue, ...props} = this.props;

    const {t} = getEvergreenTranslations(this.props);
    const selectTexts = t('select', _texts);
    const texts = t('select.searchable', _texts?.searchable);

    return (
      <FilteredListBox
        {...props}
        onUpdateActiveDescendant={this.handleActiveDescendantUpdate}
        searchValue={this.state.searchValue}
      >
        <Search
          placeholder={texts.inputPlaceholder}
          value={this.state.searchValue}
          onChange={this.handleSearch}
          aria-activedescendant={this.state.activedescendant}
          onKeyDown={this.handleArrowsPressed}
          texts={selectTexts}
        />
      </FilteredListBox>
    );
  }
}

SearchableListBox.propTypes = {
  ...FilteredListBox.propTypes,
  /** Texts for searchable listbox */
  texts: PropTypes.object,
  /** Triggered when user searches */
  onSearch: PropTypes.func,
  /** If exists it makes search input to be controlled */
  searchValue: PropTypes.string
};

export default withTranslations(SearchableListBox);
