import type {GtmClient} from 'shared/helpers/analytics/gtm';
import constructGtmClient from 'shared/helpers/analytics/gtm';
import {ExtraHeapProps, HeapAction, HeapProperty} from '../events/heap';
import {watchForConsent} from '../helpers/trackConsent';

export type GaEvent = {
  action: string;
  category: string;
  label?: string;
  nonInteractive?: boolean;
};

export type AnalyticsClient = {
  addHeapEventProperties(properties: Partial<Record<HeapProperty, string | number>>): void;
  addHeapEventProperty(key: HeapProperty, value: string | number): void;
  gaEvent(options: GaEvent, ...rest: any[]): void;
  heapEvent<Action extends HeapAction>(
    action: Action,
    ...[extraProps]: Action extends keyof ExtraHeapProps ? [ExtraHeapProps[Action]] : [undefined?]
  ): void;
  heapIdentify(userId: string): void;
  pageview(path: string, ...rest: any[]): void;
  removeHeapEventProperties(...keys: HeapProperty[]): void;
  removeHeapEventProperty(key: HeapProperty): void;
  resetHeapIdentity(): void;
  variable(key: string, value: string, ...rest: any[]): void;
};

export const wrapper = (gtm: GtmClient): AnalyticsClient => ({
  addHeapEventProperties: properties => {
    if (gtm) {
      void gtm.addHeapEventProperties(properties).send();
    }
  },
  addHeapEventProperty: (key, value) => {
    if (gtm) {
      void gtm.addHeapEventProperties({[key]: value}).send();
    }
  },
  gaEvent: ({action, category, label, nonInteractive = false}) => {
    if (gtm) {
      void gtm.event(category, action, label, {nonInteractive}).send();
    }
  },
  heapEvent: (action, ...[extraProps]) => {
    if (gtm) {
      void gtm.heapEvent(action, extraProps).send();
    }
  },
  heapIdentify: userId => {
    if (gtm) {
      void gtm.heapIdentify(userId).send();
    }
  },
  pageview: path => {
    if (gtm) {
      void gtm.pageview(path).send();
    }
  },
  removeHeapEventProperties: (...keys) => {
    if (gtm) {
      keys.forEach(key => {
        void gtm.removeHeapEventProperty(key).send();
      });
    }
  },
  removeHeapEventProperty: key => {
    if (gtm) {
      void gtm.removeHeapEventProperty(key).send();
    }
  },
  resetHeapIdentity: () => {
    if (gtm) {
      void gtm.resetHeapIdentity().send();
    }
  },
  variable: (key, value) => {
    if (gtm) {
      void gtm.variable(key, value).send();
    }
  }
});

let analyticsClient: AnalyticsClient;

const createAnalyticsClient = (): Promise<AnalyticsClient | undefined> => {
  if (!__GTM_ID__ || !__GTM_AUTH__ || !__GTM_ENV__) {
    return Promise.resolve(undefined);
  }

  return watchForConsent().then(({areAnalyticsCookiesEnabled, hasGivenConsent}) => {
    if (!hasGivenConsent || !areAnalyticsCookiesEnabled) {
      return undefined;
    }

    analyticsClient =
      analyticsClient ||
      wrapper(
        constructGtmClient({
          gtm_auth: __GTM_AUTH__,
          gtm_consent_mode_enabled: true,
          gtm_preview: __GTM_ENV__,
          id: __GTM_ID__
        })
      );

    return analyticsClient;
  });
};

export default createAnalyticsClient;
