import React from 'react';
import clsx from "clsx";
import PropTypes from 'prop-types';
import TextField from 'shared/ui/molecules/control/text';
import Select from 'shared/ui/organisms/select';
import {useEvergreenTranslations} from 'shared/ui/providers/translations';
import withMemo from 'shared/ui/helpers/hocs/withMemo';
import styles from './styles.scss';

const SelectField = ({
  id,
  error,
  warning,
  label,
  helper,
  required,
  readOnly,
  className,
  style,
  texts: _texts,
  optional,
  tooltip,
  skeleton,
  ...props
}) => {
  const controlTexts = useEvergreenTranslations('control', _texts);
  const selectTexts = useEvergreenTranslations('select', _texts);

  return (
    (<TextField
      id={id}
      error={error}
      warning={warning}
      label={label}
      helper={helper}
      style={style}
      optional={optional}
      tooltip={tooltip}
      required={required}
      className={clsx(styles.select, className)}
      readOnly={readOnly}
      labelWrap={false}
      skeleton={skeleton}
      texts={controlTexts}>
      <Select
        {...props}
        error={!!error}
        warning={!!warning}
        required={required}
        readOnly={readOnly}
        texts={selectTexts}
      />
    </TextField>)
  );
};

const MemoSelectField = withMemo(SelectField);

MemoSelectField.propTypes = {
  ...TextField.propTypes,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  /** Array to be rendered as options. */
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      /** Array of strings. eg. ['value0', 'value1'] */
      PropTypes.string,
      /** Array of objects. eg. [{value: 'value', display: 'Display'}] */
      PropTypes.shape({
        /** The value of the option. */
        value: PropTypes.string,
        /** The display text (description) of the option. */
        display: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        /** The display text of the inpute when the option is selected */
        inputDisplay: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        /** Controls whether the option is disabled or not. If disabled it cannot be selected. */
        disabled: PropTypes.bool
      })
    ])
  ),
  /** Controls whether select is searchable or not. */
  searchable: PropTypes.bool
};

MemoSelectField.displayName = 'Control.Select';

export default MemoSelectField;
