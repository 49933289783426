import React from 'react';
import clsx from "clsx";
import PropTypes from 'prop-types';
import IllustratedContainer from 'shared/ui/atoms/icon/container';
import Tooltip from 'shared/ui/organisms/tooltip';
import QuestionCircle from 'shared/ui/atoms/icon/questionCircle';
import CaretDown from 'shared/ui/atoms/icon/caretDown';
import styles from './styles.scss';

const OptionContainer = ({
  children,
  disabled,
  centered,
  active,
  visible,
  group,
  onDialogClose,
  tooltip,
  virtualized,
  expanded,
  collapsible,
  ...rest
}) => {
  const tooltipProps = typeof tooltip === 'string' ? {title: tooltip} : tooltip;

  return (
    (<IllustratedContainer {...rest} className={clsx({
      [styles.option]: true,
      [styles.disabled]: disabled,
      [styles.centered]: centered,
      [styles.active]: active,
      [styles.virtualized]: virtualized,
      [styles.expanded]: expanded
    }, rest.className)}>
      {children}
      {!!tooltip && (
        <Tooltip {...tooltipProps}>
          <QuestionCircle size="16" className={styles["tooltip-icon"]} transparent color="grey600" dataUi="tooltip-trigger" />
        </Tooltip>
      )}
      {collapsible && <CaretDown className={styles.caret} size="12" secondary />}
    </IllustratedContainer>)
  );
};

OptionContainer.propTypes = {
  /** Controls whether this option is disabled or not. If disabled, user is unable to select it. */
  disabled: PropTypes.bool,
  /** Controls whether this option is centered or not. */
  centered: PropTypes.bool,
  /** Controls whether this option is active or not. */
  active: PropTypes.bool,
  /** Controls whether to show a tooltip or not and specifies the props of the tooltip. */
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

OptionContainer.displayName = 'Listbox.ListItem.SimpleContainer';

export default React.memo(OptionContainer);
