import React, {useCallback} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import symbols from 'shared/ui/symbols';
import getRandomString from 'shared/ui/helpers/getRandomString';

import Body from 'shared/ui/atoms/text/body';
import TextSecondary from 'shared/ui/atoms/text/secondary';
import Check from 'shared/ui/atoms/icon/check';
import EllipsisWithTooltip from 'shared/ui/molecules/ellipsisWithTooltip';

import BaseSimpleItem from '../simple/base';

import styles from './styles.scss';

const ExpandableItem = React.memo(({display, selected, group, children, virtualized, ...rest}) => {
  const id = `expandable_listitem_${getRandomString(getRandomString)}`;
  const {label, content, helper} = display || {};

  const canExpand = !!content;

  const handleClick = useCallback(e => {
    e.stopPropagation();
  });

  const expandableProps = canExpand ? {'aria-owns': id, 'aria-expanded': selected} : {};

  return (
    (<div className={clsx({
      [styles["no-padding-left"]]: group || selected,
      [styles.expandable]: true
    })}>
      <BaseSimpleItem
        virtualized={virtualized}
        {...rest}
        className={clsx(styles['expandable-label'], rest.className)}
        {...expandableProps}
      >
        {selected ? <Check emphasis size="16" /> : null}
        <div className={styles['details-container']}>
          <EllipsisWithTooltip tooltipProps={{title: label}}>
            <Body
              {...(group ? {emphasis: true} : {neutral: !selected})}
              className={styles['display-text']}
              strong={group}
            >
              {label}
            </Body>
          </EllipsisWithTooltip>
          {helper ? (
            <EllipsisWithTooltip tooltipProps={{title: helper}}>
              <TextSecondary secondary={!selected} primary={selected} className={styles['helper-text']}>
                {helper}
              </TextSecondary>
            </EllipsisWithTooltip>
          ) : null}
        </div>
      </BaseSimpleItem>
      {canExpand && (
        <div id={id} className={styles['expandable-content']} data-role="expandable-content" onClick={handleClick}>
          {selected && content}
        </div>
      )}
    </div>)
  );
});

ExpandableItem.propTypes = {
  /** The description of the option that will be displayed at the user. */
  display: PropTypes.shape({
    label: PropTypes.string,
    helper: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
  }),
  /** Controls whether this option is disabled or not. If disabled, user is unable to select it. */
  disabled: PropTypes.bool,
  /** Controls whether this option is selected or not. */
  selected: PropTypes.bool,
  /** Controls whether this option is centered or not. */
  centered: PropTypes.bool,
  /** Controls whether this option is active or not. */
  active: PropTypes.bool,
  /** If set to true, denotes the specific option has a nested group, therefor is a group header */
  group: PropTypes.bool,
  /** Controls whether text will be truncated  */
  truncated: PropTypes.bool
};

ExpandableItem.displayName = 'Listbox.ListItem.Expandable';

ExpandableItem[symbols.ListItem.Expandable] = true;

export default ExpandableItem;
