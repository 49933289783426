import {useEffect} from 'react';
import type {SerializedError} from '@reduxjs/toolkit';
import type {FetchBaseQueryError} from '@reduxjs/toolkit/query/react';
import ga4 from 'shared/helpers/analytics/ga4';
import {useLazyGetUserDataQuery} from 'job-board/api/jobBoardApi';
import {useAnalytics} from 'job-board/contexts/analytics/hooks';
import {HeapProperty} from 'job-board/shared/analytics/events/heap';
import {watchForConsent} from 'job-board/shared/analytics/helpers/trackConsent';
import {FEATURE_FLAGS} from 'job-board/shared/constants/featureFlags';
import useHandleBroadcastAuth from 'job-board/shared/hooks/useHandleBroadcastAuth';
import {isPreviewMode} from 'job-board/shared/utils/mode';
import {authWithShield} from './utils/authWithShield';

const useAppMount = (): {userDataError?: FetchBaseQueryError | SerializedError} => {
  const [getUserData, {error}] = useLazyGetUserDataQuery();
  const {addHeapEventProperties, heapIdentify, removeHeapEventProperties, resetHeapIdentity} = useAnalytics();

  useEffect(() => {
    Object.entries(window.jobBoard.featureFlags || {}).forEach(([key, value]) => {
      const featureFlagValue = value?.toString ? value.toString() : value;
      addHeapEventProperties({
        // Heap doesn't play well with boolean values
        [key]: featureFlagValue
      });
    });

    if (FEATURE_FLAGS.IS_PROFILE_ENABLED) {
      const getUser = async ({shouldAuthWithShield}: {shouldAuthWithShield: boolean}): Promise<void> => {
        const {data: user, error: userDataError} = await getUserData(undefined);

        if (!user && shouldAuthWithShield) {
          await authWithShield();

          return getUser({shouldAuthWithShield: false});
        }

        if (user && !userDataError) {
          heapIdentify(user.id);
          addHeapEventProperties({[HeapProperty.LOGGED_IN]: 'true'});
        } else {
          resetHeapIdentity();
          removeHeapEventProperties(HeapProperty.LOGGED_IN);
        }
      };

      void getUser({shouldAuthWithShield: !isPreviewMode()});
    }

    // Inject ga4 script used by VJB partners
    void watchForConsent().then(({areAnalyticsCookiesEnabled}) => {
      const googleAnalyticsKey = window.jobBoard.config.googleAnalyticsKey;

      if (areAnalyticsCookiesEnabled && googleAnalyticsKey) {
        ga4(googleAnalyticsKey);
      }
    });
  }, []);

  useHandleBroadcastAuth();

  return {
    userDataError: error
  };
};

export default useAppMount;
