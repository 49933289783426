export const FEATURE_FLAGS = {
  IS_COMPLETE_PROFILE_PROMPT_ENABLED: window.jobBoard.featureFlags.wjb_complete_profile_prompt_enabled ?? false,
  IS_GOOGLE_DRIVE_UPLOAD_ENABLED: window.jobBoard.featureFlags.wjb_google_drive_upload_enabled ?? false,
  IS_PERSONALIZATION_ENABLED: window.jobBoard.featureFlags.wjb_personalization_enabled ?? false,
  IS_PROFILE_ENABLED: (() =>
    window.location.search.toLowerCase().includes('profileenabled=true') ||
    (window.jobBoard.featureFlags.candidateProfileEnabled ?? false))(),
  IS_TURNSTILE_ENABLED: window.jobBoard.featureFlags.wjb_acp_turnstile_captcha_enabled ?? false,
  SIGNUP_VARIANT: window.jobBoard.featureFlags.wjb_experiment_signup_abn
};
