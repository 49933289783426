import React from 'react';
import {useLocation} from 'react-router';
import SharedFlashAlert from 'shared/modules/contexts/flashAlert/alert';
import styles from './flashAlert.scss';

const FlashAlert = () => {
  const {pathname} = useLocation();

  return <SharedFlashAlert className={styles['flash-alert']} currentPathname={pathname} />;
};

export default FlashAlert;
