import {Option, SimpleDisplay as Display} from './types';

const filtering = (option: Option<Display>, searchValue: string): boolean => {
  let display: string | undefined;

  if (typeof option.display === 'string') {
    display = option.display;
  } else if (option.display?.label !== undefined) {
    display = option.display.label;
  } else {
    display = option.display?.title;
  }

  const searchable = display || option.value;

  return typeof searchable !== 'undefined' && `${searchable}`.toLowerCase().includes(searchValue.toLowerCase());
};

export default filtering;
