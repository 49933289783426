import React from 'react';
import Option from '../simple/nonSelectable';

const EmptyOption = props => <Option {...props} centered />;

EmptyOption.propTypes = {
  ...Option.propTypes
};

EmptyOption.displayName = 'Listbox.ListItem.Empty';

export default EmptyOption;
