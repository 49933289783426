import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import symbols from 'shared/ui/symbols';
import propsFilter from 'shared/ui/helpers/propsFilter';
import getRandomString from 'shared/ui/helpers/getRandomString';
import createPseudoEvent from 'shared/ui/helpers/createPseudoEvent';
import getClosestById from 'shared/ui/helpers/getClosestById';
import {setSymbol} from 'shared/ui/atoms/icon/base';
import {withTranslations, getEvergreenTranslations} from 'shared/ui/providers/translations';

import TextBody from 'shared/ui/atoms/text/body';
import TextSecondary from 'shared/ui/atoms/text/secondary';
import Dropdown from 'shared/ui/organisms/dropdown';
import Button from 'shared/ui/atoms/button';
import Pill from 'shared/ui/atoms/pill';
import KeyboardHandlers from 'shared/ui/behaviors/keyboardHandler';
import {ListBox, SearchableListBox} from 'shared/ui/organisms/listbox';
import {SimpleSelectableItem, EmptyItem} from 'shared/ui/organisms/listbox/listItem';

import {ROLE as DIALOG_ROLES} from 'shared/ui/organisms/dialog/base/constants';

import Input from './input';

import styles from './styles.scss';

const NOOP = () => {};

const DIRECTIONS = KeyboardHandlers.constants.DIRECTIONS;

const isValid = val => val !== '' && val !== undefined && val !== null;
const isArrayValid = val => Array.isArray(val) && val.filter(Boolean).length > 0;

const isSelected = (option, value) => {
  return (Array.isArray(value) ? value : [value]).includes(
    isValid(option.value) ? option.value || `${option.value}` : option
  );
};

const IconsContainer = props => <div {...props} />;
setSymbol(IconsContainer);

class Select extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.defaultOpen === true,
      draftOptions: []
    };

    this.containerRef = React.createRef();
  }

  static getDerivedStateFromProps(props, prevState) {
    if (props.confirmable && prevState.open === false) {
      return {
        ...prevState,
        draftOptions: props.value || []
      };
    }
    return null;
  }

  isMultiselectAndConfirmable() {
    return this.props.confirmable && this.props.multiSelect;
  }

  mappedOptions(rawOptions) {
    const selectedValue = this.isMultiselectAndConfirmable() ? this.state.draftOptions : this.props.value;

    return rawOptions.map(option => {
      const {value, options, display, ...rest} = option;

      if (options instanceof Array && options.length > 0) {
        return {
          ...rest,
          value: isValid(value) ? value : display,
          display: isValid(display) ? display : value,
          options: this.mappedOptions(options),
          selected: isSelected(option, selectedValue)
        };
      }
      return typeof option === 'string'
        ? {value: option, display: option, selected: isSelected(option, selectedValue)}
        : {...option, selected: isSelected(option, selectedValue)};
    });
  }

  getDisplayValue(options) {
    const {multiSelect, displayValue} = this.props;

    const selectedOption = this.findSelectedOption(options);

    if (!displayValue) {
      return (selectedOption && selectedOption.display) || '';
    }

    const value = multiSelect
      ? this.findSelectedOptions(options).map(option => option.value)
      : selectedOption
        ? selectedOption.value
        : undefined;

    return typeof displayValue === 'function'
      ? displayValue({
          value,
          options
        })
      : displayValue;
  }

  getIds() {
    const {id} = this.props;

    return {
      dialogId: `${id}_dialog`,
      inputId: `${id}_input`,
      listboxId: `${id}_listbox`
    };
  }

  /**
   * Find the first selected option in a nested array of options
   * @param {Array} mappedOptions
   * @returns {Object|undefined}
   */
  findSelectedOption(mappedOptions = []) {
    let firstFound;

    for (let i = 0; i < mappedOptions.length; i = i + 1) {
      const option = mappedOptions[i];
      if (firstFound) {
        break;
      }
      if (option.selected) {
        firstFound = option;
        break;
      }
      if (option.options instanceof Array && option.options.length) {
        firstFound = this.findSelectedOption(option.options);
      }
    }
    return firstFound;
  }

  /**
   * Find all selected options in a nested array of options
   * @param {Array} mappedOptions
   * @returns {Array}
   * */
  findSelectedOptions(mappedOptions = []) {
    const foundOptions = [];

    for (let i = 0; i < mappedOptions.length; i = i + 1) {
      const option = mappedOptions[i];
      if (option.selected) {
        foundOptions.push(option);
      }
      if (option.options instanceof Array && option.options.length) {
        foundOptions.push(...this.findSelectedOptions(option.options));
      }
    }
    return foundOptions;
  }

  openDialog = () => {
    const {readOnly, disabled} = this.props;

    if (readOnly || disabled) {
      return;
    }

    if (typeof this.props.onOpen === 'function') {
      this.props.onOpen();
    }

    this.setState({open: true});
  };

  closeDialog = event => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
    this.setState({open: false});
  };

  handleArrows = (event, {direction}) => {
    event.preventDefault();

    const upClicked = direction === DIRECTIONS.UP;
    const downClicked = direction === DIRECTIONS.DOWN;

    if (upClicked || downClicked) {
      this.openDialog();
    }
  };

  handleSingleSelection = (activeElement, event) => {
    if (!activeElement) {
      return;
    }

    const activeValue = activeElement.getAttribute('value');
    const isDisabled = activeElement.hasAttribute('disabled');
    const isPresentational = activeElement.hasAttribute('data-presentational');

    if (!activeValue || isDisabled || isPresentational) {
      if (event) {
        event.preventDefault();
      }
      return;
    }

    this.triggerOnSelect(activeValue);
    this.triggerOnChange(activeValue);

    this.closeDialog();
  };

  handleMultiSelection = activeElement => {
    if (!activeElement) {
      return;
    }

    const activeValue = activeElement.getAttribute('value');
    const isDisabled = activeElement.hasAttribute('disabled');
    const isPresentational = activeElement.hasAttribute('data-presentational');

    if (!activeValue || isDisabled || isPresentational) {
      return;
    }

    const updatedArrayValue = this.updateArrayValue(activeValue);

    this.triggerOnSelect(activeValue);
    this.triggerOnChange(updatedArrayValue);
  };

  handleClearDrafts = () => {
    if (!this.isMultiselectAndConfirmable()) {
      return;
    }

    this.setState(state => ({...state, draftOptions: []}));
  };

  triggerOnChange(value, force = false) {
    if (!force && this.isMultiselectAndConfirmable()) {
      this.setState({draftOptions: value});
      return;
    }
    const event = createPseudoEvent(value, this.props.name);

    this.props.onChange(event);
  }

  triggerOnSelect(value) {
    if (this.isMultiselectAndConfirmable()) {
      return false;
    }

    const event = createPseudoEvent(value, this.props.name);
    this.props.onSelect(event);
  }

  triggerOnConfirmedChange = event => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const {draftOptions} = this.state;
    const {value = []} = this.props;
    const draftOptionsIsEmpty = draftOptions.length === 0;
    const valueIsEmpty = value && value.length === 0;

    if (draftOptionsIsEmpty && valueIsEmpty) {
      this.closeDialog();
      return;
    }

    if (draftOptions instanceof Array && value instanceof Array) {
      if (draftOptions.sort().join('') === value.sort().join('')) {
        this.closeDialog();
        return;
      }
    }

    const pseudoEvent = createPseudoEvent(this.state.draftOptions, this.props.name);
    this.props.onChange(pseudoEvent);
    this.closeDialog();
  };

  updateArrayValue(activeValue) {
    const {value: propsValue = []} = this.props;
    const value = this.isMultiselectAndConfirmable()
      ? this.state.draftOptions || []
      : propsValue instanceof Array
        ? propsValue
        : [propsValue];

    const newArrayValue = [...value];

    if (newArrayValue.includes(activeValue)) {
      const index = newArrayValue.indexOf(activeValue);

      if (index !== -1) {
        newArrayValue.splice(index, 1);
      }
    } else {
      newArrayValue.push(activeValue);
    }

    return newArrayValue;
  }

  handleClearSelectedOptions = event => {
    event.preventDefault();

    const {multiSelect} = this.props;

    const value = multiSelect ? [] : '';

    this.triggerOnChange(value, true);
  };

  isSearchable() {
    const {options, searchable, onSearch} = this.props;

    if (typeof onSearch === 'function') {
      return searchable;
    }

    if (searchable === false) {
      return false;
    }

    if (searchable === true && options?.length > 1) {
      return true;
    }

    if (options.length >= 5) {
      return true;
    }

    const flatOptions = options?.flatMap(({options: _options = [], ...o}) => [o, ..._options]);

    return flatOptions?.length >= 5 || (searchable === true && flatOptions?.length > 2);
  }

  renderListbox(options, id) {
    const {
      multiSelect,
      labelId,
      Option,
      EmptyOption,
      texts: _texts,
      virtualized,
      dynamicItemHeight,
      virtualizedItemHeight,
      virtualizedListHeight,
      disableSmoothScrolling,
      onSearch,
      filtering,
      highlighting,
      showScrollingShadow
    } = this.props;

    const {t} = getEvergreenTranslations(this.props);
    const texts = t('select', _texts);

    const selectionHandler = multiSelect ? this.handleMultiSelection : this.handleSingleSelection;

    const searchable = this.isSearchable();

    const ResolvedListBox = searchable ? SearchableListBox : ListBox;

    const hasEmptyOptionBody = !!texts.empty.body;

    return (
      <ResolvedListBox
        id={id}
        texts={searchable ? texts : undefined}
        onSelect={selectionHandler}
        items={options}
        labelId={labelId}
        Item={Option}
        emptyItem={
          <EmptyOption>
            <TextBody as="div" neutral={!hasEmptyOptionBody} strong={hasEmptyOptionBody}>
              {texts.empty.title}
            </TextBody>
            {texts.empty.body && <TextSecondary muted>{texts.empty.body}</TextSecondary>}
          </EmptyOption>
        }
        aria-multiselectable={multiSelect}
        selectOnTab={!multiSelect}
        focusFirst
        selectOnSpace={!searchable}
        className={clsx({
          [styles['without-search']]: !searchable
        })}
        virtualized={virtualized}
        virtualizedItemHeight={virtualizedItemHeight}
        virtualizedListHeight={virtualizedListHeight}
        dynamicItemHeight={dynamicItemHeight}
        disableSmoothScrolling={disableSmoothScrolling}
        onSearch={onSearch}
        filtering={filtering}
        highlighting={highlighting}
        showScrollingShadow={showScrollingShadow}
      />
    );
  }

  handleFocus = e => {
    const {dialogId, inputId} = this.getIds();

    const isInternal = e.target?.id === inputId || this.containerRef.current?.isEqualNode(e.target);
    const isFromDialog = !!getClosestById(e.relatedTarget, dialogId);

    if (typeof this.props.onFocus === 'function' && isInternal && !isFromDialog) {
      this.props.onFocus(e);
    }
  };

  handleBlur = e => {
    const {dialogId, inputId} = this.getIds();

    const isInternal = !!getClosestById(e.relatedTarget, dialogId);
    const isFocusingToInput = e.relatedTarget?.id === inputId;

    if (typeof this.props.onBlur === 'function' && !isInternal && !isFocusingToInput) {
      this.props.onBlur(e);
    }
  };

  handleTabPress = () => {
    if (this.state.open) {
      this.closeDialog();
    }
  };

  renderListboxActions() {
    const {texts: _texts} = this.props;
    const {t} = getEvergreenTranslations(this.props);
    const texts = t('select', _texts);

    return (
      <div className={styles['options-actions-container']} role="menu">
        <Button.Tertiary
          neutral
          as="a"
          className={styles['options-actions']}
          onClick={this.handleClearDrafts}
          role="menuitem"
          type="button"
        >
          {texts.confirmable.actions.clear}
        </Button.Tertiary>
        <Button.Tertiary
          as="a"
          onClick={this.triggerOnConfirmedChange}
          className={styles['options-actions']}
          role="menuitem"
          type="button"
        >
          {texts.confirmable.actions.apply}
        </Button.Tertiary>
      </div>
    );
  }

  processChildren() {
    let alert;
    const leftIcons = [];
    const rightIcons = [];
    const restChildren = [];

    React.Children.forEach(this.props.children, (child, key) => {
      if (typeof child === 'string') {
        return restChildren.push(child);
      }

      if (!child || !React.isValidElement(child)) {
        return;
      }

      if (child.type[symbols.Alert.Static]) {
        alert = React.cloneElement(child, {'data-role': 'static-alert'});
        return;
      }

      if (child.type[symbols.Icon]) {
        if (child.props.left) {
          return leftIcons.push(React.cloneElement(child, {key}));
        }

        return rightIcons.push(React.cloneElement(child, {key}));
      }

      restChildren.push(React.cloneElement(child, {key}));
    });

    return {alert, leftIcons, rightIcons, restChildren};
  }

  handlePillDismiss = valueToBeRemoved => {
    const updatedArrayValue = this.updateArrayValue(valueToBeRemoved);

    this.triggerOnChange(updatedArrayValue);
  };

  PillsContainer = ({value, options}) => {
    const isDismissable = !this.props.readOnly;

    const selectedValues = (Array.isArray(value) ? value : [value]).filter(Boolean);

    return (
      <div className={styles['pills-container']}>
        {selectedValues.map((selectedValue, index) => {
          let displayOption = {};

          options.forEach(option => {
            if (Array.isArray(option.options)) {
              option.options.forEach(_option => {
                if (_option.value === selectedValue) {
                  displayOption = _option;
                }
              });
            }

            if (option.value === selectedValue) {
              displayOption = option;
            }
          });

          const displayValue = (displayOption.display && displayOption.display.label) || displayOption.display;

          return (
            <Pill
              key={index}
              onDismiss={isDismissable ? () => this.handlePillDismiss(selectedValue) : undefined}
              data-ui="select-pill"
              disabled={this.props.disabled || displayOption.disabled}
            >
              {displayValue || selectedValue}
            </Pill>
          );
        })}
      </div>
    );
  };

  getDisplayContainer(mappedOptions) {
    const {pills, displayContainer} = this.props;

    if (pills) {
      return this.PillsContainer;
    }

    if (displayContainer) {
      return displayContainer;
    }

    const selectedOption = this.findSelectedOption(mappedOptions);
    if (selectedOption && isValid(selectedOption.inputDisplay)) {
      return () =>
        typeof selectedOption.inputDisplay === 'function'
          ? selectedOption.inputDisplay(selectedOption)
          : selectedOption.inputDisplay;
    }
  }

  render() {
    const {
      id,
      value,
      placeholder,
      required,
      readOnly,
      disabled,
      error,
      warning,
      clearable,
      texts: _texts,
      name,
      onKeyDown,
      onKeyPress,
      onKeyUp,
      as,
      elevatedDropdown,
      fit = true,
      hideOnTargetHidden,
      ...props
    } = this.props;
    const {t} = getEvergreenTranslations(this.props);
    const texts = t('select', _texts);

    const dataProps = propsFilter(props).dataAttributes().styles().getFiltered();

    const {dialogId, inputId, listboxId} = this.getIds();

    const {open} = this.state;

    const displayClearIcon =
      !disabled && !readOnly && clearable && isValid(value) && (Array.isArray(value) ? isArrayValid(value) : true);

    const mappedOptions = this.mappedOptions(this.props.options);

    const {alert, leftIcons, rightIcons, restChildren} = this.processChildren();

    const DisplayContainer = this.getDisplayContainer(mappedOptions);

    const hasCustomDisplayContainer = DisplayContainer && (Array.isArray(value) ? isArrayValid(value) : isValid(value));

    const searchable = this.isSearchable();

    return (
      <KeyboardHandlers handleArrowsPressed={this.handleArrows} handleTabPressed={this.handleTabPress}>
        <div
          ref={this.containerRef}
          {...dataProps}
          className={clsx(
            styles.select,
            {
              [styles.elevated]: elevatedDropdown,
              [styles['custom-container']]: hasCustomDisplayContainer
            },
            dataProps.className
          )}
          data-open={open}
          data-input-type="select"
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          {...(hasCustomDisplayContainer ? {tabIndex: 0} : {})}
        >
          <Input
            as={as}
            clearable={displayClearIcon}
            searchable={searchable}
            onClear={this.handleClearSelectedOptions}
            open={open}
            popupId={listboxId}
            id={inputId}
            value={this.getDisplayValue(mappedOptions)}
            placeholder={placeholder}
            onClick={this.openDialog}
            readOnly={readOnly || undefined}
            disabled={disabled}
            error={error}
            warning={warning}
            aria-label={this.props['aria-label']}
            aria-labelledby={this.props['aria-labelledby']}
            onKeyDown={onKeyDown}
            onKeyPress={onKeyPress}
            onKeyUp={onKeyUp}
            texts={texts}
          >
            {leftIcons.length > 0 && (
              <IconsContainer left data-role="icons-container">
                {leftIcons}
              </IconsContainer>
            )}
            {hasCustomDisplayContainer && (
              <IconsContainer left data-role="custom-display-container">
                {typeof DisplayContainer === 'function' ? (
                  <DisplayContainer value={value} options={mappedOptions} />
                ) : (
                  {DisplayContainer}
                )}
              </IconsContainer>
            )}
            {rightIcons.length > 0 && <IconsContainer data-role="icons-container-right">{rightIcons}</IconsContainer>}
          </Input>
          <Dropdown
            id={dialogId}
            open={open}
            onClose={this.closeDialog}
            className={styles.dropdown}
            role={DIALOG_ROLES.PRESENTATION}
            focusable
            fit={fit}
            hideOnTargetHidden={hideOnTargetHidden}
          >
            {alert}
            {this.renderListbox(mappedOptions, listboxId)}
            {this.isMultiselectAndConfirmable() && this.renderListboxActions()}
            {restChildren}
          </Dropdown>
          <input
            name={name}
            onChange={NOOP}
            value={value || ''}
            required={required}
            className={styles['hidden-input']}
            tabIndex="-1"
            aria-hidden="true"
          />
        </div>
      </KeyboardHandlers>
    );
  }
}

Select.displayName = 'Input.Select';

Select.defaultProps = {
  options: [],
  id: getRandomString(),
  Option: SimpleSelectableItem,
  EmptyOption: EmptyItem,
  onChange: NOOP,
  onSelect: NOOP,
  confirmable: false,
  showScrollingShadow: true
};

Select.propTypes = {
  /** The id to be used as prefix for all elements inside select. Default value is generated from `shared/ui/helpers/getRandomString` */
  id: PropTypes.string,
  /** The id of the label. */
  labelId: PropTypes.string,
  /** Select input value. */
  value(props, propName) {
    const prop = props[propName];
    if (props.multiSelect) {
      if (prop !== undefined && !Array.isArray(prop)) {
        return new Error(
          `Invalid prop \`${propName}\` of type \`${typeof prop}\` supplied to \`${
            Select.displayName
          }\`. When 'multiSelect' prop is true, then '${propName}' must be an array.`
        );
      }
    } else if (prop && typeof prop !== 'string' && typeof prop !== 'number') {
      return new Error(
        `Invalid prop \`${propName}\` of type \`${typeof prop}\` supplied to \`${
          Select.displayName
        }\`. Must be string or number.`
      );
    }
  },
  /** onChange handler, triggers passing all values on each change */
  onChange: PropTypes.func,
  /** onSelect handler, triggers passing only the currently selected value */
  onSelect: PropTypes.func,
  /** Array to be rendered as options. */
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      /** Array of strings. eg. ['value0', 'value1'] */
      PropTypes.string,
      /** Array of objects. eg. [{value: 'value', display: 'Display'}] */
      PropTypes.shape({
        /** The value of the option. */
        value: PropTypes.string,
        /** The display text (description) of the option. */
        display: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        /** The display text of the input when the option is selected */
        inputDisplay: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        /** Whether the option is disabled or not. If disabled it cannot be selected. */
        disabled: PropTypes.bool,
        /** This option is used for presentational purposes only. */
        presentational: PropTypes.bool,
        /** Passing more nested options will render them as grouped select elements  */
        options: PropTypes.array
      })
    ])
  ),
  /** Select input name. */
  name: PropTypes.string,
  /** Select input placeholder. */
  placeholder: PropTypes.string,
  /** Renders a disabled select input. User is unable to click it. */
  disabled: PropTypes.bool,
  /** Has error. Style input as error. */
  error: PropTypes.bool,
  /** Has warning. Style input as warning. */
  warning: PropTypes.bool,
  /** Renders a readonly select input. User is unable to change it. */
  readOnly: PropTypes.bool,
  /** Select input is required. */
  required: PropTypes.bool,
  /** Controls whether select is searchable or not. */
  searchable: PropTypes.bool,
  /** Controls whether select has 'X' icon to clear selected options. */
  clearable: PropTypes.bool,
  /** Controls whether the user can select multiple options. */
  multiSelect: PropTypes.bool,
  /** This callback is triggered when select opens. */
  onClose: PropTypes.func,
  /** This callback is triggered when select closes. */
  onOpen: PropTypes.func,
  /** Overrides and controls the display value of the IllustratedInput */
  displayValue: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /** Allows to display a component in the input instead of default input value */
  displayContainer: PropTypes.func,
  /** Adds cancel and confirm actions at the component */
  confirmable: PropTypes.bool,
  /** Whether the scrolling to the selected item (during focus) is animated or not. */
  disableSmoothScrolling: PropTypes.bool,
  /**
   * A filter function which will be executed for each option against the search query
   * @param {object} option
   * @param {string} searchValue
   * @returns {boolean}
   * */
  filtering: PropTypes.func,
  /** A highlighter function which will be executed for each option against the search query. */
  highlighting: PropTypes.func,
  /** Controls if it will show a shadow at the bottom of the container when scrolling is available */
  showScrollingShadow: PropTypes.bool,
  /** All the texts might be used inside the component.*/
  texts: PropTypes.object
};

export default withTranslations(Select);
