import React from 'react';
import clsx from "clsx";
import PropTypes from 'prop-types';
import symbols from 'shared/ui/symbols';
import ImageOrTextAvatar from 'shared/ui/organisms/nav/avatar/imageOrTextAvatar';
import EllipsisWithTooltip from 'shared/ui/molecules/ellipsisWithTooltip';
import Body from 'shared/ui/atoms/text/body';
import TextSecondary from 'shared/ui/atoms/text/secondary';
import Check from 'shared/ui/atoms/icon/check';
import BaseSimpleItem from '../simple/base';
import styles from './styles.scss';

const LISTBOX_AVATAR_SIZE = 30;

function getTextContent(elem) {
  if (!elem) {
    return '';
  }
  if (typeof elem === 'string') {
    return elem;
  }
  const children = elem.props && elem.props.children;
  if (children instanceof Array) {
    return children.map(getTextContent).join('');
  }
  return getTextContent(children);
}

const ListItemWithAvatar = React.memo(({display, selected, group, search, ...rest}) => {
  const {label, helper, src, size = LISTBOX_AVATAR_SIZE, seed, avatarInitials} = display || {};

  if (!label) {
    return null;
  }

  const labelTextContent = getTextContent(label);
  const helperTextContent = getTextContent(helper);

  return (
    (<BaseSimpleItem {...rest} className={clsx({
      [styles.selectable]: true,
      [styles["no-padding-left"]]: group || selected
    }, rest.className)}>
      {selected ? <Check emphasis size="16" /> : null}
      <div className={styles['avatar-container']}>
        <ImageOrTextAvatar email={seed || helper} name={avatarInitials || labelTextContent} src={src} size={size} />
        <div className={styles['details-container']}>
          <EllipsisWithTooltip tooltipProps={{title: labelTextContent}}>
            <Body
              {...(group ? {emphasis: true} : {neutral: !selected})}
              className={styles['display-text']}
              strong={group}
            >
              {label}
            </Body>
          </EllipsisWithTooltip>
          {helper ? (
            <EllipsisWithTooltip tooltipProps={{title: helperTextContent}}>
              <TextSecondary secondary={!selected} primary={selected} className={styles['helper-text']}>
                {helper}
              </TextSecondary>
            </EllipsisWithTooltip>
          ) : null}
        </div>
      </div>
    </BaseSimpleItem>)
  );
});

ListItemWithAvatar.propTypes = {
  /** The description of the option that will be displayed at the user. */
  display: PropTypes.shape({
    label: PropTypes.string,
    helper: PropTypes.string,
    src: PropTypes.string,
    size: PropTypes.number
  }),
  /** Controls whether this option is disabled or not. If disabled, user is unable to select it. */
  disabled: PropTypes.bool,
  /** Controls whether this option is selected or not. */
  selected: PropTypes.bool,
  /** Controls whether this option is centered or not. */
  centered: PropTypes.bool,
  /** Controls whether this option is active or not. */
  active: PropTypes.bool,
  /** The search term used to active highlighting. Used on `FilteredListBox` and `SearchableListBox`. */
  search: PropTypes.string,
  /** If set to true, denotes the specific option has a nested group, therefor is a group header */
  group: PropTypes.bool
};

ListItemWithAvatar.displayName = 'Listbox.ListItem.WithAvatar';

ListItemWithAvatar[symbols.ListItem.WithAvatar] = true;

export default ListItemWithAvatar;
