import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import personAdd from 'assets/svg/personAdd24.svg?sprite';
import ButtonSecondary from 'shared/ui/atoms/button/secondary';
import TextBody from 'shared/ui/atoms/text/body';
import {routesPaths} from 'job-board/routes/constants';
import Svg from 'job-board/shared/components/svg';
import styles from './loginButton.scss';

type LoginButtonProps = {
  isMobile: boolean;
};

const LoginButton = ({isMobile}: LoginButtonProps): JSX.Element => {
  const {t} = useTranslation();

  const BUTTON_LABEL = t('header.login-button');

  if (isMobile) {
    return (
      <Link aria-label={BUTTON_LABEL} className={styles.button} to={routesPaths.LOGIN}>
        <Svg height={24} id={personAdd.id} width={24} />
      </Link>
    );
  }

  return (
    <ButtonSecondary as={Link} className={styles.button} small to={routesPaths.LOGIN}>
      <TextBody className={styles.text} strong>
        {BUTTON_LABEL}
      </TextBody>
    </ButtonSecondary>
  );
};

export default memo(LoginButton);
