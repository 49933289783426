import React, {createContext, ReactNode, useState} from 'react';

export const HasRouteChangedContext = createContext<boolean | undefined>(undefined);
export const HasRouteChangedUpdaterContext = createContext<((hasRouteChanged: boolean) => void) | undefined>(undefined);

export const HasRouteChangedProvider = ({children}: {children: ReactNode}) => {
  const [hasRouteChanged, setHasRouteChanged] = useState(false);

  return (
    <HasRouteChangedContext.Provider value={hasRouteChanged}>
      <HasRouteChangedUpdaterContext.Provider value={setHasRouteChanged}>
        {children}
      </HasRouteChangedUpdaterContext.Provider>
    </HasRouteChangedContext.Provider>
  );
};
