import React, {Fragment} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import symbols from 'shared/ui/symbols';
import getRandomString from 'shared/ui/helpers/getRandomString';
import propsFilter from 'shared/ui/helpers/propsFilter';
import IllustratedContainer from 'shared/ui/atoms/icon/container';
import IconCaretDown from 'shared/ui/atoms/icon/caretDown';
import PrimaryButton from 'shared/ui/atoms/button/primary';
import DropDown, {SIZES, POSITIONS} from 'shared/ui/organisms/dropdown';
import {ListBox} from 'shared/ui/organisms/listbox';
import KeyboardHandler from 'shared/ui/behaviors/keyboardHandler';
import SimpleNonSelectableItem from 'shared/ui/organisms/listbox/listItem/simple/nonSelectable';
import {SkeletonButton} from 'shared/ui/atoms/skeleton';
import DropDownOption from './option';

import styles from './styles.scss';

const getDefaultHorizontalPosition = ({right, center, defaultHorizontalPosition}) => {
  if (right) {
    return POSITIONS.RIGHT;
  }

  if (center) {
    return POSITIONS.HORIZONTAL_CENTER;
  }

  if (defaultHorizontalPosition) {
    return defaultHorizontalPosition;
  }

  return POSITIONS.LEFT;
};

const {UP, DOWN} = KeyboardHandler.constants.DIRECTIONS;

const findButtonByValue = (options = [], value) => {
  for (let i = 0; i < options.length; i = i + 1) {
    const option = options[i];
    if (option.value === value) {
      return option.button;
    }
    if (option.options) {
      const found = findButtonByValue(option.options, value);
      if (found) {
        return found;
      }
    }
  }
};

const DATA_EXPANDABLE_GROUP_OF_LINKS = 'data-group-of-links';

/**
 * @class DropDownButton
 * @extends {React.Component}
 *
 * Renders a dropdown button styled in the desired button Kind with a list of options.
 * Options are passed as Button elements. Upon selection of an option the `onClick` attached
 * on the corresponding button element passed will be called. If an option is disabled set
 * the prop `disabled` on its button representation.
 * @example
 * <DropDownButton as={PrimaryButton}>
 *    Click me
 *    <PrimaryButton onClick={clickFirst}>Option 1</PrimaryButton>
 *    <PrimaryButton onClick={clickSecond}>Option 2</PrimaryButton>
 *    <PrimaryButton disabled onClick={clickDisabled}>Option Disabled</PrimaryButton>
 * </DropDownButton>
 *
 * @
 * You can also add a custom Dropdown to the button component by passing in its children a
 * `DropDown` component. In that case keep in mind to handle all the accessibility concerns.
 *  Any other buttons on the same level with the custom DropDown will be ignored.
 *  @example
 * <DropDownButton as={PrimaryButton}>
 *    Click me
 *    <DropDown>
 *      <div> I am in a custom dropdown </div>
 *    </DropDown>
 * </DropDownButton>
 */
class DropDownButton extends React.Component {
  static getDerivedStateFromProps(props) {
    if ('open' in props) {
      return {
        open: Boolean(props.open)
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    const {id = getRandomString()} = props;
    this.domId = `${id}_dropdown`;
    this.domEl = undefined;
  }

  state = {open: false, expandedItemValue: undefined};

  closeDropDown = event => {
    if (event && event.stopPropagation) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (typeof this.props.onClose === 'function') {
      this.props.onClose(event);
    }

    this.toggleOpen(false);
  };

  handleButtonClicked = event => {
    if (typeof this.props.onClick === 'function') {
      this.props.onClick(event);
    }

    this.toggleOpen();
  };

  toggleOpen(value) {
    if (this.isControlled()) {
      return;
    }

    this.setState(state => ({open: value !== undefined ? value : !state.open}));
  }

  _toggleOpen(event) {
    return this.isControlled()
      ? this.props.open
        ? this.closeDropDown(event)
        : this.handleButtonClicked(event)
      : this.toggleOpen();
  }

  handleArrowPress = (event, {direction}) => {
    if ([UP, DOWN].includes(direction)) {
      this._toggleOpen(event);
    }
  };

  isControlled() {
    return 'open' in this.props;
  }

  handleOptionSelected = (selectedNode, event) => {
    if (!selectedNode) {
      return;
    }

    if (selectedNode.hasAttribute('disabled')) {
      return;
    }

    const selectedNodeValue = selectedNode.getAttribute('value');

    const isExpandableItem = selectedNode.getAttribute(DATA_EXPANDABLE_GROUP_OF_LINKS) === 'true';
    if (isExpandableItem) {
      const isGroupExpanded = selectedNode.getAttribute('aria-expanded') === 'true';

      this.setState({expandedItemValue: isGroupExpanded ? undefined : selectedNodeValue});

      return;
    }

    const button = findButtonByValue(this.options, selectedNodeValue);

    if (button && typeof button.props.onClick === 'function') {
      button.props.onClick(event);
    } else if (button.props.href) {
      window.open(button.props.href, button.props.target);
    }

    if (this.props.keepOpenAfterSelection) {
      return;
    }

    this.closeDropDown(event);
  };

  processChildren(children = this.props.children, nestLevel = [0]) {
    const processedOptions = [];
    const processedChildren = [];
    const isInGroup = children !== this.props.children;
    let hasIcon = false;
    let hasSelectedItem = false;
    let hasGroup = false;
    let customDropDown;
    let alert;
    let footer;

    React.Children.forEach(children, (child, key) => {
      if (typeof child === 'string') {
        return processedChildren.push(child);
      }

      if (!child || !React.isValidElement(child)) {
        return;
      }

      if (child.type[symbols.Alert.Static]) {
        if (child.props.footer) {
          footer = React.cloneElement(child, {'data-role': 'footer'});
          return;
        }

        alert = React.cloneElement(child, {'data-role': 'static-alert'});
        return;
      }

      if (child.type[symbols.Dialog.DropDown]) {
        customDropDown = child;
        return;
      }

      if (child.type[symbols.Group.Button]) {
        hasGroup = true;

        const newLevel = [nestLevel[0] + 1, key];
        const {processedChildren: subChildren, processedOptions: subOptions = []} = this.processChildren(
          child.props.children,
          newLevel
        );

        const value = `group_${newLevel.join('_')}`;

        const isExpandable = child.props.expandable;

        const isGroupExpanded = this.state.expandedItemValue === value;

        const options = isExpandable
          ? subOptions.map(option => {
              // Keep the group hidden when it is not expanded
              return isGroupExpanded ? option : {};
            })
          : subOptions;

        return processedOptions.push({
          options,
          value,
          display: subChildren.length ? <Fragment>{subChildren}</Fragment> : null,
          presentational: !isExpandable,
          expanded: isExpandable ? isGroupExpanded : undefined,
          [DATA_EXPANDABLE_GROUP_OF_LINKS]: isExpandable || undefined,
          ...child.props
        });
      }

      if (symbols.Button.includes(child.type)) {
        hasSelectedItem = hasSelectedItem || child.props.selected;

        return processedOptions.push({
          ...child.props,
          value: `${nestLevel.join('_')}_${processedOptions.length.toString()}`,
          disabled: child.props.disabled,
          selected: child.props.selected,
          button: child,
          display: child.props.children
        });
      }

      if (child.type[symbols.Icon]) {
        hasIcon = true;
      }

      return processedChildren.push(React.cloneElement(child, {key}));
    });

    if (!hasIcon && !isInGroup) {
      processedChildren.push(
        <IconCaretDown
          key="caret"
          className={clsx(styles.caret, this.state.open && styles.open)}
          transparent
          size={14}
        />
      );
    }

    const processedChildrenWithoutIcon = React.Children.map(processedChildren, child => {
      if (!child) {
        return;
      }

      if (child && child.type && child.type[symbols.Icon]) {
        return;
      }

      return child;
    });

    return {
      processedOptions,
      processedChildren,
      processedChildrenWithoutIcon,
      customDropDown,
      hasSelectedItem,
      alert,
      hasGroup,
      footer
    };
  }

  render() {
    const {
      as: MorphKind = PrimaryButton,
      children, // eslint-disable-line no-unused-vars
      disabled: isDisabled,
      type = 'button',
      className,
      responsive,
      Option,
      right,
      center,
      size,
      onOpen,
      backdrop,
      transparent,
      disableAutoPlacement,
      defaultVerticalPosition,
      defaultHorizontalPosition,
      dropdownClassName,
      dropdownAsDrawer,
      drawerLabel,
      isHorizontalList,
      skeleton,
      skeletonProps,
      fit,
      containerDataUi,
      keepOpenAfterSelection,
      ...props
    } = this.props;

    if (skeleton) {
      return <SkeletonButton data-ui="skeleton-button-dropdown" {...skeletonProps} />;
    }

    const {
      processedOptions,
      processedChildren,
      processedChildrenWithoutIcon,
      customDropDown,
      hasSelectedItem,
      hasGroup,
      alert,
      footer
    } = this.processChildren();
    const hasPopup = customDropDown ? 'true' : 'menu';

    const composePropsFilter = propsFilter(props).like(/^onTransition/);
    const transitionProps = composePropsFilter.getFiltered();
    const restProps = composePropsFilter.excludeFiltered();

    const dropDownProps = {
      ...transitionProps,
      open: this.state.open,
      onClose: this.closeDropDown,
      focusBack: false,
      onOpen,
      backdrop,
      transparent,
      size,
      disableAutoPlacement,
      defaultVerticalPosition,
      defaultHorizontalPosition: getDefaultHorizontalPosition({right, center, defaultHorizontalPosition}),
      dropdownAsDrawer,
      drawerLabel: typeof drawerLabel === 'string' ? drawerLabel : processedChildrenWithoutIcon,
      fit: fit !== undefined ? fit : !!responsive,
      ...(customDropDown && customDropDown.props),
      id: this.domId
    };

    this.options = processedOptions;

    if (!customDropDown && processedOptions.length === 0) {
      throw new Error('No options found. Expected to receive children Button elements as options');
    }

    const hasSelectableItems = this.isControlled() || hasSelectedItem || hasGroup;

    return (
      <div
        ref={el => (this.domEl = el)}
        className={clsx({[styles.placement]: true, [styles.responsive]: responsive}, className)}
        data-ui={containerDataUi}
      >
        <div className={styles.button}>
          <KeyboardHandler handleArrowsPressed={this.handleArrowPress}>
            <IllustratedContainer
              type={type}
              {...restProps}
              as={MorphKind}
              responsive={responsive}
              onClick={this.handleButtonClicked}
              disabled={isDisabled}
              aria-haspopup={hasPopup}
              aria-expanded={this.state.open}
              aria-orientation={isHorizontalList ? 'horizontal' : undefined}
            >
              {processedChildren}
            </IllustratedContainer>
          </KeyboardHandler>
          {customDropDown ? (
            <customDropDown.type className={clsx(styles.options, dropdownClassName)} {...dropDownProps} />
          ) : (
            <DropDown className={clsx(styles.options, dropdownClassName)} {...dropDownProps}>
              {alert}
              <ListBox
                focusFirst={false}
                ariaType={ListBox.constants.TYPE.MENU}
                onSelect={this.handleOptionSelected}
                items={processedOptions}
                labelId={`${this.domId}_label`}
                Item={Option ? Option : hasSelectableItems ? DropDownOption : SimpleNonSelectableItem}
                isHorizontalList={isHorizontalList}
                data-autofocus
              />
              {footer}
            </DropDown>
          )}
        </div>
      </div>
    );
  }
}

DropDownButton.propTypes = {
  ...PrimaryButton.propTypes,
  /** The component that will render each option. */
  Option: PropTypes.func,
  /** Places the dropdown dialog left related to the button */
  left: PropTypes.bool,
  /** Places the dropdown dialog right related to the button */
  right: PropTypes.bool,
  /** Set the width of dropdown. */
  size: PropTypes.oneOf(Object.keys(SIZES)),
  /** Renders the dropdown as modal for tablet and bottom-drawer for mobile. */
  dropdownAsDrawer: PropTypes.bool,
  /** Controls whether the list is horizontal in order to adjust listbox keyboard handling and accessibility. */
  isHorizontalList: PropTypes.bool,
  /** The data-ui for container */
  containerDataUi: PropTypes.string,
  /** When set to true, the dropdown will remain open after an option is selected. */
  keepOpenAfterSelection: PropTypes.bool
};

DropDownButton[symbols.Button.DropDown] = true;

export default DropDownButton;

export {SIZES};
