import React, {memo} from 'react';
import {useLocation} from 'react-router-dom';
import clsx from 'clsx';
import {routesPaths} from 'job-board/routes/constants';
import {BottomRow, TopRow} from './components';
import styles from './footer.scss';

const Footer = () => {
  const location = useLocation();

  return (
    <footer
      className={clsx(styles.footer, {
        [styles['avoid-sticky-apply-button']]: location.pathname.startsWith(routesPaths.JOB)
      })}
    >
      <div className={styles['content-container']}>
        <TopRow />
        <BottomRow />
      </div>
    </footer>
  );
};

export default memo(Footer);
