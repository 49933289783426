import React from 'react';
import PropTypes from 'prop-types';
import Button from 'shared/ui/atoms/button';
import symbols from 'shared/ui/symbols';

const AvatarAction = ({icon, size, onClick, label, children, ...props}) => {
  const actionIcon = icon || React.Children.only(children);
  if (!actionIcon || !actionIcon.type || !actionIcon.type[symbols.Icon]) {
    throw new Error('An icon is required to be passed into avatar action');
  }
  return (
    <Button.Tertiary {...props} onClick={onClick} neutral as="div" title={label} aria-label={label}>
      {React.cloneElement(actionIcon, {
        size,
        transparent: true
      })}
    </Button.Tertiary>
  );
};

AvatarAction.propTypes = {
  /** An icon component to pass into avatar action. Alternatively can be passed as only child */
  icon: PropTypes.element,
  /** The size of the avatar */
  size: PropTypes.number,
  /** A label will be used on button title text and for aria purposes */
  label: PropTypes.string,
  /** Click event handler */
  onClick: PropTypes.func
};

AvatarAction[symbols.Avatar.Action] = true;

export default AvatarAction;
