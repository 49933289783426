/* eslint-disable react/jsx-max-depth */
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Provider} from 'react-redux';
import {ResizeObserverProvider} from 'shared/contexts/container';
import {FlashAlertProvider} from 'shared/modules/contexts/flashAlert/provider';
import {EvergreenTranslationsProvider} from 'shared/ui/providers/translations';
import type {Locale} from 'shared/ui/providers/translations/types';
import {AnalyticsProvider} from 'job-board/contexts/analytics/provider';
import {DatadogProvider} from 'job-board/contexts/datadog/provider';
import {HasRouteChangedProvider} from 'job-board/contexts/hasRouteChanged/provider';
import {RollbarProvider} from 'job-board/contexts/rollbar/provider';
import {ServerErrorProvider} from 'job-board/contexts/serverError/provider';
import {store} from 'job-board/redux';
import {ErrorBoundary} from 'job-board/shared/components/errors';
import ThemeProvider from 'job-board/shared/theme/themeProvider';

const AppProviders = ({children}: {children: React.ReactNode}) => {
  const {i18n} = useTranslation();

  return (
    <EvergreenTranslationsProvider locale={i18n.language as Locale}>
      <ThemeProvider />
      <Provider store={store}>
        <RollbarProvider>
          <ErrorBoundary>
            <ResizeObserverProvider>
              <ServerErrorProvider>
                <HasRouteChangedProvider>
                  <AnalyticsProvider>
                    <DatadogProvider>
                      <FlashAlertProvider>{children}</FlashAlertProvider>
                    </DatadogProvider>
                  </AnalyticsProvider>
                </HasRouteChangedProvider>
              </ServerErrorProvider>
            </ResizeObserverProvider>
          </ErrorBoundary>
        </RollbarProvider>
      </Provider>
    </EvergreenTranslationsProvider>
  );
};

export default AppProviders;
