import {useLayoutEffect, useRef} from 'react';
import {matchPath} from 'react-router';
import {useLocation} from 'react-router-dom';
import {useCompanyAttributionMutation, useJobAttributionMutation} from 'job-board/api/jobBoardApi';
import {useSetHasRouteChanged} from 'job-board/contexts/hasRouteChanged/hooks';
import {routesPaths} from 'job-board/routes/constants';

const Attributions = () => {
  const location = useLocation();
  const previousLocation = useRef<string>();
  const setHasRouteChanged = useSetHasRouteChanged();
  const [jobAttribution] = useJobAttributionMutation({selectFromResult: () => ({})});
  const [companyAttribution] = useCompanyAttributionMutation({selectFromResult: () => ({})});

  useLayoutEffect(() => {
    window.scrollTo({top: 0});
    // We make the attribution call ONLY when the user visits the job/company page through the app because when the page
    // is requested and loaded from the server, the back-end makes the attribution call instead.
    if (previousLocation.current) {
      setHasRouteChanged(true);
      const jobRouteMatch = matchPath(`${routesPaths.JOB}/:jobId/*`, location.pathname);
      const companyRouteMatch = matchPath(`${routesPaths.COMPANY}/:companyId/*`, location.pathname);

      if (jobRouteMatch && jobRouteMatch.params?.jobId) {
        void jobAttribution({id: jobRouteMatch.params.jobId, position: null});
      } else if (companyRouteMatch && companyRouteMatch.params?.companyId) {
        void companyAttribution(companyRouteMatch.params.companyId);
      }
    }

    previousLocation.current = location.pathname;
  }, [location.pathname]);

  return null;
};

export default Attributions;
