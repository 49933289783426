import React from 'react';
import clsx from "clsx";
import PropTypes from 'prop-types';
import getRandomString from 'shared/ui/helpers/getRandomString';
import {IllustratedInput} from 'shared/ui/atoms/input/text';
import RemoveIcon from 'shared/ui/atoms/icon/remove';
import CaretDownIcon from 'shared/ui/atoms/icon/caretDown';
import ActionableIcon from 'shared/ui/atoms/icon/actionable';
import {useEvergreenTranslations} from 'shared/ui/providers/translations';

import styles from './styles.scss';

const noopFn = () => undefined;

const onLoad = input => {
  if (input && typeof input.setAttribute === 'function') {
    input.setAttribute('readonly', true);
  }

  // Set cursor at the beginning of the input to avoid input glitch,
  // when input content is wider than input width.
  if (input && 'setSelectionRange' in input && input.selectionStart !== 0) {
    input.setSelectionRange(0, 0);
  }
};

const Input = ({
  open,
  popup = 'listbox',
  popupId,
  clearable,
  searchable,
  texts: _texts,
  onClear,
  children,
  className,
  ...rest
}) => {
  const texts = useEvergreenTranslations('select', _texts);

  return (
    (<label className={clsx(styles.wrapper, className)}>
      <IllustratedInput
        role="combobox"
        aria-haspopup={popup}
        aria-expanded={open}
        aria-owns={popupId}
        onChange={noopFn}
        aria-controls={popupId}
        aria-autocomplete={searchable ? 'list' : 'none'}
        autoComplete="off"
        onLoad={onLoad}
        {...rest}
        className={clsx({
          [styles.input]: true,
          [styles.open]: open
        }, rest.className)}>
        {clearable ? (
          <ActionableIcon
            handleEnterPressed={onClear}
            handleSpacePressed={onClear}
            onClick={onClear}
            aria-label={texts.clearable.title}
          >
            <RemoveIcon muted size="12" />
          </ActionableIcon>
        ) : (
          <CaretDownIcon muted right className={styles.icon} />
        )}
        {children}
      </IllustratedInput>
    </label>)
  );
};
Input.displayName = 'Select.Input';

Input.defaultProps = {
  open: false,
  id: getRandomString(),
  clearable: false,
  onClear: noopFn
};

Input.propTypes = {
  id: PropTypes.string,
  popupId: PropTypes.string,
  clearable: PropTypes.bool,
  open: PropTypes.bool,
  texts: PropTypes.object,
  onClear: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
};

export default Input;
