import {useContext} from 'react';
import {ServerErrorContext, ServerErrorUpdaterContext} from './provider';

export const useHasServerError = () => {
  const hasServerError = useContext(ServerErrorContext);
  if (typeof hasServerError === 'undefined') {
    throw new Error('useHasServerError must be used within a ServerErrorProvider');
  }
  return hasServerError;
};

export const useSetHasServerError = () => {
  const setHasServerError = useContext(ServerErrorUpdaterContext);
  if (typeof setHasServerError === 'undefined') {
    throw new Error('useSetHasServerError must be used within a ServerErrorProvider');
  }
  return setHasServerError;
};
