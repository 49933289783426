import queryString from 'query-string';
/**
 * Loads an image and returns it
 * @param {String} url The url to load the image from or a base64 encoded image
 * @returns {HTMLImageElement} The image loaded
 */

const isS3Bucket = url => url && url.indexOf && url.indexOf('s3.amazonaws.com') !== -1;
const isSignedS3Url = url => url && url.indexOf && url.indexOf('X-Amz-Signature') !== -1;
const isCloudFront = url => url && url.indexOf && url.indexOf('.cloudfront.') !== -1;

const CORS_PARAM = 'evergreen-cors';

const addCorsParam = url => {
  const parsedUrl = queryString.parseUrl(url);
  parsedUrl.query = parsedUrl.query || {};
  parsedUrl.query[CORS_PARAM] = true;
  return `${parsedUrl.url}?${queryString.stringify(parsedUrl.query)}`;
};

export const removeCorsParam = url => {
  if (typeof url !== 'string') {
    return url;
  }

  const parsedUrl = queryString.parseUrl(url);
  parsedUrl.query = parsedUrl.query || {};
  parsedUrl.query[CORS_PARAM] = undefined;

  const queryParams = queryString.stringify(parsedUrl.query);

  return `${parsedUrl.url}${queryParams !== '' ? `?${queryParams}` : ''}`;
};

export const setImageOptions = (image = {}, url) => {
  if (url.startsWith('http') && image.setAttribute) {
    image.setAttribute('crossOrigin', 'anonymous');
  }

  image.src = (isS3Bucket(url) && !isSignedS3Url(url)) || isCloudFront(url) ? addCorsParam(url) : url;
  return image;
};

const loadImage = url => {
  return Promise.resolve(url).then(urlResolved => {
    return new Promise((resolve, reject) => {
      const image = document.createElement('img');
      setImageOptions(image, urlResolved || '');
      image.onerror = reject;
      image.onload = () => resolve(image);
    });
  });
};

export default loadImage;
