import isEqual from 'react-fast-compare';

type PrimitiveValue = string | number | boolean;

export type QueryParams = Record<string, PrimitiveValue | PrimitiveValue[] | null | undefined>;

export const buildUrlSearchParams = (queryParams: QueryParams, existingSearchParams?: URLSearchParams): string =>
  Object.entries(queryParams)
    .reduce((searchParams, [paramName, paramValue]) => {
      if (!paramValue || (Array.isArray(paramValue) && !paramValue.length)) {
        searchParams.delete(paramName);
        return searchParams;
      }

      if (Array.isArray(paramValue)) {
        searchParams.delete(paramName);

        paramValue.forEach(value => {
          searchParams.append(paramName, value.toString());
        });
      } else {
        searchParams.set(paramName, paramValue.toString());
      }

      return searchParams;
    }, new URLSearchParams(existingSearchParams))
    // `setSearchParams` internally checks whether the new URL search params is of `URLSearchParams` instance.
    // For some reason this check returns false in some old browsers (e.g. Chrome 109) and as a result the new
    // URL search params are never set. In order to bypass that problematic check `toString` is used.
    .toString();

export const checkUrlSearchParamsHaveChanged = (urlSearchParams: string, newUrlSearchParams: string): boolean => {
  return !isEqual(
    Array.from(new URLSearchParams(urlSearchParams)).sort(),
    Array.from(new URLSearchParams(newUrlSearchParams)).sort()
  );
};
