import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TransformedPaginatedCompany} from 'job-board/api/types/getPaginatedCompany';

type CompanyDataState = {
  data: TransformedPaginatedCompany;
};

export const initialState: CompanyDataState = {
  data: {
    description: '',
    id: '',
    image: '',
    jobs: [],
    nextPageToken: '',
    title: '',
    totalSize: 0,
    url: '',
    website: ''
  }
};

const companyDataSlice = createSlice({
  initialState,
  name: 'companyData',
  reducers: {
    clearCompanyState: () => initialState,
    loadMoreCompanyJobs: (state, action: PayloadAction<{data: TransformedPaginatedCompany}>) => ({
      ...state,
      data: {
        ...action.payload.data,
        jobs: [...state.data.jobs, ...action.payload.data.jobs]
      }
    }),
    setCompanyData: (_, action: PayloadAction<{data: TransformedPaginatedCompany}>) => action.payload
  }
});

export const {clearCompanyState, loadMoreCompanyJobs, setCompanyData} = companyDataSlice.actions;

export default companyDataSlice.reducer;
