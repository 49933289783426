import React, {ReactNode} from 'react';
import Outline from 'shared/ui/atoms/outline';
import Footer from '../footer';
import Nav from '../nav';
import styles from './layout.scss';

type LayoutProps = {
  children: ReactNode;
};

const Layout = ({children}: LayoutProps) => {
  return (
    <>
      <Outline />
      <Nav />
      <main className={styles.main}>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
