import React from 'react';
import symbols from 'shared/ui/symbols';
import BaseSimpleItem from '../base';
import Display from '../display';

const SimpleNonSelectableItem = React.memo(({display, virtualized, children, group, ...props}) => (
  <BaseSimpleItem virtualized={virtualized} {...props}>
    <Display truncated={virtualized} display={display} group={group}>
      {children}
    </Display>
  </BaseSimpleItem>
));

SimpleNonSelectableItem.displayName = 'Listbox.ListItem.SimpleNonSelectable';

SimpleNonSelectableItem[symbols.ListItem.SimpleNonSelectable] = true;

export default SimpleNonSelectableItem;
